/* eslint-disable */
import React, { useEffect, useState } from "react";
import '../../styles/advertisement.css';
import { useForm } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import advertisementDetailsLabel from "../labels/advertisement_details_en.json";
import common from "../labels/common_en.json";
import DocumentList from "./DocumentList";
import ReviewSelectedModal from "./ReviewSelectedModal";
import { toastr } from "react-redux-toastr";
import dayjs from 'dayjs';
import {
    getAdvertisementDetails, getCheckEligibility, resetApplicationPostPref, downloadAdvertisementPdf,
    doApply, doPayment, downloadDocument, postUploadDocument, getUploadDocument, resetUploadeDocumentChecks, closePaymentModal, getDepartments, getAllDepartments
} from '../actions/advertisementAction';
import ProfileDetails from './ProfileDetails';
import QuestionDetails from './QuestionDetails';
import { BOOLEAN_OPTION, SCRIBE_OPTION } from '../../common/constants/dropdownOptions';
import { Modal } from 'react-bootstrap';
import Select, { components } from "react-select";
import { hideLoader, showLoader } from '../../common/actions/commonActions';
import commonLabels from "../../my_account/labels/common_en.json";
import myAccountLabels from "../../my_account/labels/my_account_en.json";

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);
    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };
    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" className="me-2" checked={isSelected} />
            {children}
        </components.Option>
    );
};
const AdvertisementDetails = () => {

    const [dummy, setDummy] = useState(0);
    const [isNext, setIsNext] = useState(false);
    const [selectedExamCenters, setSelectedExamCenters] = useState('');
    const [declAccepted, setDeclAccepted] = useState(false);
    const [additionalAnswers, additionalAnswersState] = useState({});
    const [location, setLocation] = useState('');
    const [flgDisabilityCompTime, setFlgDisabilityCompTime] = useState('');
    const [flgDisabilityScribeReq, setFlgDisabilityScribeReq] = useState('');
    const [scribeArrangedBy, setScribeArrangedBy] = useState('');
    const [showDocListModal, setshowDocListModal] = useState(false);
    const [singleDocList, setSingleDocList] = useState("");
    let [showChallan, setShowChallan] = useState(true);
    let [showOnlinePayment, setShowOnlinePayment] = useState(true);
    let [showSbi, setShowSbi] = useState(true);
    let [showBillDesk, setShowBillDesk] = useState(true);
    let [showEaseBuzz, setShowEaseBuzz] = useState(true);
    let [showCsc, setShowCsc] = useState(true);
    let [showQuickwallet, setShowQuickwallet] = useState(true);
    const [selectedOptionErr, setSelectedOptionErr] = useState(true);
    const [selectedDepartmentArr, setSelectedDepartmentArr] = useState([]);
    const [selectedTempDepartmentArr, setSelectedTempDepartmentArr] = useState([]);
    const [reviewDepartmentModal, setReviewDepartmentModal] = useState(false);
    const [deptOptions, setDeptOptions] = useState([]);
    const [currentPostId, setOpenModelPostid] = useState([]);
    const [reloadItem, setReloadItem] = useState(0);
    const [someOptionsThisPost, setSomeOptionsThisPost] = useState([]);
    const [examSubjectConfig, setExamSubjectConfig] = useState([])
    const [selectedSubjects, setSelectedSubjects] = useState([])
    const [subjectMedium, setSubjectMedium] = useState([])

    const handleQuestions = (evt) => {
        const value = evt.target.value;
        additionalAnswersState({
            ...additionalAnswers,
            [evt.target.name]: value,
        });
    };

    const { register, formState, watch } = useForm({
        mode: "onChange"
    });
    let { advertisementDetailsInfo, advertisementId, eligibilityResultList, applicableAdditionalQuestionsList, applicationPostPrefList, examCenterOptionList, profileInfo,
        applicationFee, proceedToPayment, applyedApplication, uploadedDocumentData, setCheckEligibilty, allDepartments, allPostsDepartments } = useSelector(state => state.advertisement, shallowEqual);
    const dispatch = useDispatch();
    const maximumNoCenter = 1
    const challanDownloadDate = applyedApplication?.challanDownloadEndDate != null ? applyedApplication?.challanDownloadEndDate : applyedApplication?.applnEndDate

    useEffect(() => {
        setDeptOptions([]);
        if(applicationPostPrefList && applicationPostPrefList.length>0 && hasExamObject(applicationPostPrefList)) {
            let temp = applicationPostPrefList.filter((item) => {
                return item.exam.optionalSubjectCount && item.exam.optionalSubjectCount > 0;
            }).map((item) => {
                return {
                    "examId": item.exam.id,
                    "subjects": [],
                    "optionalSubjectCount": item.exam.optionalSubjectCount
                };
            });
            let mediumConfig = applicationPostPrefList.reduce((acc, { exam }) => {
                if (exam.examSubjectDto && exam.examSubjectDto.length > 0) {
                  const subjects = exam.examSubjectDto.reduce((subAcc, { id, medium, subjectAllowedList, subjectNotAllowedList }) => {
                    subAcc[id] = { medium, id, subjectAllowedList, subjectNotAllowedList };
                    return subAcc;
                  }, {});
                  
                  acc[exam.id] = { ...acc[exam.id], ...subjects };
                }
                return acc;
              }, {});
            subjectMedium.length === 0 && setSubjectMedium(mediumConfig)
            examSubjectConfig.length === 0 && setExamSubjectConfig(temp)
        }
        if(hasExamObject(applicationPostPrefList)){
            applicationPostPrefList && applicationPostPrefList?.flatMap(exam=>exam.eligibilityResultDtos).map((postPref, index) => {
                allPostsDepartments?.map((pos, ind) => {
                    if (pos.postCriteriaId == postPref.postCriteriaId) {
                        let opt = {
                            'pid': postPref.postCriteriaId,
                            "options": pos.appointintAuthorities
                        }
                        setDeptOptions((arr) => [...arr, opt])
                    }
                })
            }
            )
        }else{
            applicationPostPrefList && applicationPostPrefList?.map((postPref, index) => {
                allPostsDepartments?.map((pos, ind) => {
                    if (pos.postCriteriaId == postPref.postCriteriaId) {
                        let opt = {
                            'pid': postPref.postCriteriaId,
                            "options": pos.appointintAuthorities
                        }
                        setDeptOptions((arr) => [...arr, opt])
                    }
                })
            }
            )
        }
        
    }, [allPostsDepartments, applicationPostPrefList]);

    useEffect(() => {
        dispatch(getAdvertisementDetails(advertisementId));
        dispatch(getAllDepartments(advertisementId))
    }, []);

    const downloadPdfFile = () => {
        dispatch(downloadAdvertisementPdf(advertisementId));
    };

    const checkEligibility = () => {
        dispatch(getCheckEligibility(advertisementId));
    };

    const computeIsEligible = (postCriteriaId) => {
        const eligibilityResult = eligibilityResultList?.find(elgbResult => elgbResult.postCriteriaId == postCriteriaId);
        if (!eligibilityResult) {
            return null;
        }
        return eligibilityResult.isEligible;
    };

    const computeIsEligibleMsg = (postCriteriaId) => {
        const eligibilityResult = eligibilityResultList?.find(elgbResult => elgbResult.postCriteriaId == postCriteriaId);
        if (!eligibilityResult) {
            return null;
        }
        return eligibilityResult.message;
    };

    const getElibilityIndicator = (postCriteriaId) => {
        const isEligible = computeIsEligible(postCriteriaId);
        const message = computeIsEligibleMsg(postCriteriaId);
        const text = message && message.split("\n")
        if (isEligible == null) {
            return;
        }
        return isEligible ?
            <span className="text-success">{advertisementDetailsLabel.YouAreEligiblePost}</span>
            :
            <span className="text-danger">
                <p className="text-danger">{advertisementDetailsLabel.YouAreNotEligiblePost}</p> {text.map((ele, i) => (
                    <p key={i} className="mb-n1">{ele}</p>))}
            </span>
    }
    const computeMultiExamIsEligible = (postCriteriaId, examId) => {
        const eligibilityExamResult = eligibilityResultList?.find(examDto => examDto.exam.id == examId);

        const eligibilityResult = eligibilityExamResult?.eligibilityResultDtos?.find(elgbResult => elgbResult.postCriteriaId == postCriteriaId);
        if (!eligibilityResult) {
            return null;
        }
        return eligibilityResult.isEligible;
    };

    const computeMultiExamIsEligibleMsg = (postCriteriaId, examId) => {
        const eligibilityExamResult = eligibilityResultList?.find(examDto => examDto.exam.id == examId);
        const eligibilityResult = eligibilityExamResult?.eligibilityResultDtos?.find(elgbResult => elgbResult.postCriteriaId == postCriteriaId);

        if (!eligibilityResult) {
            return null;
        }
        return eligibilityResult.message;
    };
    const getMultiExamElibilityIndicator = (postCriteriaId, examId ) => {
        const isEligible = computeMultiExamIsEligible(postCriteriaId, examId);
        const message = computeMultiExamIsEligibleMsg(postCriteriaId, examId);
        const text = message && message.split("\n")
        if (isEligible == null) {
            return;
        }
        return isEligible ?
            <span className="text-success">{advertisementDetailsLabel.YouAreEligiblePost}</span>
            :
            <span className="text-danger">
                <p className="text-danger">{advertisementDetailsLabel.YouAreNotEligiblePost}</p> {text.map((ele, i) => (
                    <p key={i} className="mb-n1">{ele}</p>))}
            </span>
    }

    const getPostName = (postCriteriaId, isDvng=false, examId) => {
        if(advertisementDetailsInfo?.data?.examPostList){
           const examPost = advertisementDetailsInfo?.data?.examPostList?.find(examPost => examPost.postCriteria.id == postCriteriaId);
            if (!examPost) {
                return `Unknown (${postCriteriaId})`
            }
            return isDvng ? examPost.postCriteria.nameDvng : examPost.postCriteria.name; 
        }else{
            const eligibilityExamResult = advertisementDetailsInfo?.data?.advertisementExamPostDtos?.find(examDto => examDto.exam.id == examId);
            const examPost = eligibilityExamResult?.examPostList?.find(examPost => examPost.postCriteria.id == postCriteriaId);
            if (!examPost) {
                return `Unknown (${postCriteriaId})`
            }
            return isDvng ? examPost.postCriteria.nameDvng : examPost.postCriteria.name; 

        }
        
        
    }

    const nextApply = () => {
        setIsNext(true);
        if(hasExamObject(applicationPostPrefList)){
            let post = applicationPostPrefList.flatMap(exam => exam.eligibilityResultDtos)
                .filter(pref => pref.isInterested)
                .map((pref, index) => ({ ...pref, prefOrder: index + 1 }))
            dispatch(getUploadDocument(advertisementId, post))
            
            // let temp = applicationPostPrefList.filter((item, index) => {
            //     return item.eligibilityResultDtos.some((pref, prefIndex) => pref.isInterested)
                
            // }).filter((item)=>{
            //     if(item?.exam?.optionalSubjectCount && item.exam.optionalSubjectCount > 0){
            //     return {
            //         "examId": item?.exam?.id,
            //         "subjects": [],
            //         "optionalSubjectCount": item?.exam?.optionalSubjectCount

            //     }
            // } return false
            // })
            let temp = applicationPostPrefList.filter((item, index) => {
                return item.eligibilityResultDtos.some((pref, prefIndex) => pref.isInterested)
                
            }).filter((item) => {
                return item.exam.optionalSubjectCount && item.exam.optionalSubjectCount > 0;
            }).map((item) => {
                return {
                    "examId": item.exam.id,
                    "subjects": [],
                    "optionalSubjectCount": item.exam.optionalSubjectCount
                };
            });
            setExamSubjectConfig(temp)
        }else{
            let post = applicationPostPrefList
                .filter(pref => pref.isInterested)
                .map((pref, index) => ({ ...pref, prefOrder: index + 1 }))
            dispatch(getUploadDocument(advertisementId, post))
        }
    };

    const doNext = () => {
        nextApply();
    };

    useEffect(() => { }, [reloadItem]);
    
    // ----------* new handlePrefChange *------------------------------------------------------------------------------------------------------ 
    const handlePrefChange = (postPref, name, value, postCritId) => {
        postPref[name] = value;
        setDummy(dummy + 1);
        const newArr = [];
        if(hasExamObject(applicationPostPrefList)){
            applicationPostPrefList.flatMap(exam => exam.eligibilityResultDtos).find((e) => {
                if (e.isInterested == true && e.isAssignAuthority == true) {
                    newArr.push(e.postCriteriaId);
                }
                else if (e.isInterested == false && e.isAssignAuthority == true) {
                    removeFromSelectedDeptArray(postPref);
                    let uniqueChars = [];
                    someOptionsThisPost.forEach((c) => {
                        if (!uniqueChars.includes(c)) {
                            setSomeOptionsThisPost(uniqueChars);
                        }
                    });
                };
            });
        } else{
            applicationPostPrefList.find((e) => {
                if (e.isInterested == true && e.isAssignAuthority == true) {
                    newArr.push(e.postCriteriaId);
                }
                else if (e.isInterested == false && e.isAssignAuthority == true) {
                    removeFromSelectedDeptArray(postPref);
                    let uniqueChars = [];
                    someOptionsThisPost.forEach((c) => {
                        if (!uniqueChars.includes(c)) {
                            setSomeOptionsThisPost(uniqueChars);
                        }
                    });
                };
            });
        }
        if (newArr.length > 0 && (someOptionsThisPost && someOptionsThisPost.indexOf(postCritId) != -1)) {
            setSelectedOptionErr(false)
        } else if (newArr.length > 0) {
            setSelectedOptionErr(true)
        }
        else {
            setSelectedOptionErr(false)
        };
        deptOptions.filter((ele) => ele.pid == postCritId).map((elem, index) => {
            if (elem.options.mumbai.length == 0 && elem.options.outsideMumbai.length == 0) {
                setSelectedOptionErr(false)
            }
        });
        if (postPref.isInterested === false) {
            removeFromSelectedDeptArray(postPref);
        };

        if (postPref.isAssignAuthority === true && postPref.isInterested === true) {
            dispatch(getDepartments(postPref.postCriteriaId))
        };
        if(hasExamObject(applicationPostPrefList)){
            applicationPostPrefList.flatMap(exam => exam.eligibilityResultDtos).filter((e) => {
                if (e.postCriteriaId === postPref.postCriteriaId) {
                        if ((e.isInterested == false && (e.isAssignAuthority == true || e.isAssignAuthority == false) && e.preferredAppointingAuthorities) || (e.isInterested == true && e.isAssignAuthority == false && !e.preferredAppointingAuthorities)) {
                        setSelectedOptionErr(true);
                    }
                    else
                            if ((e.isInterested == true && e.isAssignAuthority == true && !e.preferredAppointingAuthorities) || (e.isInterested == false)) {
                        let isAnyValidPost = applicationPostPrefList.flatMap(exam => exam.eligibilityResultDtos).some(e=>e.isInterested==true && e.isAssignAuthority == false)
                        if(!isAnyValidPost){
                            setSelectedOptionErr(false);
                        }
                        else{
                            setSelectedOptionErr(true);
                        }
                        }
                } else if (e.postCriteriaId !== postPref.postCriteriaId) {
                    if (e.isInterested == true && e.isAssignAuthority == true && !e.preferredAppointingAuthorities) {
                        setSelectedOptionErr(false);
                    }
                }
                if ((e.isInterested == true && e.isAssignAuthority == true && someOptionsThisPost.length <= 0)) {
                        setSelectedOptionErr(false);
                }
            });
        }else{
            applicationPostPrefList.filter((e) => {
                if (e.postCriteriaId === postPref.postCriteriaId) {
                    if ((e.isInterested == false && 
                        (e.isAssignAuthority == true || e.isAssignAuthority == false)
                         && e.preferredAppointingAuthorities)
                          || (e.isInterested == true && e.isAssignAuthority == false && !e.preferredAppointingAuthorities)) {
                        setSelectedOptionErr(true);
                        let isAnyAuthNotValid = applicationPostPrefList.some(e=>e.isInterested == true && e.isAssignAuthority == true && someOptionsThisPost.length <= 0)
                        if(isAnyAuthNotValid){
                            setSelectedOptionErr(false);
                        }
                    }
                    else
                        if ((e.isInterested == true && e.isAssignAuthority == true && !e.preferredAppointingAuthorities) || (e.isInterested == false)) {
                            let isAnyValidPost = applicationPostPrefList.some(e=>e.isInterested==true && e.isAssignAuthority == false)
                            if(!isAnyValidPost){
                                setSelectedOptionErr(false);
                            }
                            else{
                                setSelectedOptionErr(true);
                            }
                    }
                } else if (e.postCriteriaId !== postPref.postCriteriaId) {
                    if (e.isInterested == true && e.isAssignAuthority == true && !e.preferredAppointingAuthorities) {
                        setSelectedOptionErr(false);
                    }
                }
                if ((e.isInterested == true && e.isAssignAuthority == true && someOptionsThisPost.length <= 0)) {
                        setSelectedOptionErr(false);
                }
            });
        }
        
    };

    const handleExamSelection = (name, value, examId, exam) => {
        exam.eligibilityResultDtos && exam.eligibilityResultDtos.length > 0 && exam.eligibilityResultDtos.map((postPref)=>{
            postPref[name] = value
        }) 
        let isAnyValidPost = applicationPostPrefList.flatMap(exam => exam.eligibilityResultDtos).some(e=>e.isInterested==true && e.isAssignAuthority == false)
                        if(!isAnyValidPost){
                            setSelectedOptionErr(false);
                        }else{
                            setSelectedOptionErr(true);
                        }
        setDummy(dummy + 1);
    }
    // ---------------------------------------------------------------------

    const handleDocChange = (doc, isUploaded, value) => {
        doc[isUploaded] = value;
        setDummy(dummy + 1);
    };

    const removeFromSelectedDeptArray = (postData) => {
        let newArray = selectedTempDepartmentArr && selectedTempDepartmentArr.filter((eleN) => eleN.pid != postData.postCriteriaId);
        setSelectedTempDepartmentArr(newArray);
    };

    const applyInfo = () => {
        const foundTrueValue = uploadedDocumentData && uploadedDocumentData.find(element => !element.isUploaded);
        if (foundTrueValue) {
            toastr.error(advertisementDetailsLabel.UPLOAD_DOC_INFO_NOTE);
        }
        else {
            let additionalAnswers1;
            if (additionalAnswers.length == 0) { additionalAnswers1 = null; } else { additionalAnswers1 = additionalAnswers; }
            let applnData = {}
                if(hasExamObject(applicationPostPrefList)){

                    let examData = applicationPostPrefList.map(exam => {
                        const updatedEligibility = exam.eligibilityResultDtos
                            .filter(pref => pref.isInterested) 
                            .map((pref, index) => ({ ...pref, prefOrder: index + 1, preferredAppointingAuthorities: selectedDepartmentArr.length > 0 ? JSON.stringify(selectedDepartmentArr.filter((ele) => ele.pid == pref.postCriteriaId).map((elem) => elem.selectedDepartment && elem.selectedDepartment.length > 0 && elem.selectedDepartment.map((dep) => dep.id))[0]) : null }))
                    
                        return {
                            examId:exam.exam.id,
                            applicationExamPostPref: updatedEligibility
                        };
                    });
                    
                    
                    applicationPostPrefList.map(exam => exam.eligibilityResultDtos) 
                    .flat() 
                    .filter(pref => pref.isInterested) 
                    .map((pref, index) => ({ ...pref, prefOrder: index + 1, preferredAppointingAuthorities: selectedDepartmentArr.length > 0 ? JSON.stringify(selectedDepartmentArr.filter((ele) => ele.pid == pref.postCriteriaId).map((elem) => elem.selectedDepartment && elem.selectedDepartment.length > 0 && elem.selectedDepartment.map((dep) => dep.id))[0]) : null }))

                    applnData = {
                        advertisementId,
                        location,
                        additionalAnswers: JSON.stringify(additionalAnswers1),
                        applicationPostPrefList:null,
                        examMultiplePostPrefDtoList: examData,
                        applicationExamCenterPrefList:
                            selectedExamCenters
                                .map(examCenterOption => ({ examCenterId: examCenterOption.value })),
                        flgDisabilityCompTime,
                        flgDisabilityScribeReq,
                        scribeArrangedBy,
                        amountPayable: applicationFee,
                        requiredDocumentsSelected:
                            uploadedDocumentData
                                .map((doc) => ({ ...doc, documentInfoId: doc.isUploaded ? doc.documentInfoId : null })),
                        additionalQuestions: JSON.stringify(applicableAdditionalQuestionsList),
                        optionalSubjectDtoList : examSubjectConfig
                    }

                }else{
                    applnData = {
                        advertisementId,
                        location,
                        additionalAnswers: JSON.stringify(additionalAnswers1),
                        applicationPostPrefList:
                            applicationPostPrefList
                                .filter(pref => pref.isInterested)
                                .map((pref, index) => ({ ...pref, prefOrder: index + 1, preferredAppointingAuthorities: selectedDepartmentArr.length > 0 ? JSON.stringify(selectedDepartmentArr.filter((ele) => ele.pid == pref.postCriteriaId).map((elem) => elem.selectedDepartment && elem.selectedDepartment.length > 0 && elem.selectedDepartment.map((dep) => dep.id))[0]) : null })),
                        applicationExamCenterPrefList:
                            selectedExamCenters
                                .map(examCenterOption => ({ examCenterId: examCenterOption.value })),
                        flgDisabilityCompTime,
                        flgDisabilityScribeReq,
                        scribeArrangedBy,
                        amountPayable: applicationFee,
                        requiredDocumentsSelected:
                            uploadedDocumentData
                                .map((doc) => ({ ...doc, documentInfoId: doc.isUploaded ? doc.documentInfoId : null })),
                        additionalQuestions: JSON.stringify(applicableAdditionalQuestionsList)
                    }

                }

             
            dispatch(doApply(applnData));
            const enabledPaymentMethods = JSON.parse(advertisementDetailsInfo && advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisementConfig && advertisementDetailsInfo.data.advertisementConfig.enabledPaymentMethods);

            const disabledPaymentMethods = JSON.parse(advertisementDetailsInfo && advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisementConfig && advertisementDetailsInfo.data.advertisementConfig.disabledPaymentMethods)

            if (enabledPaymentMethods !== null) {

                setShowChallan(enabledPaymentMethods.includes("challan"));

                setShowOnlinePayment(enabledPaymentMethods.includes("sbiepay") ||
                    enabledPaymentMethods.includes("billdesk") ||
                    enabledPaymentMethods.includes("csc") ||
                    enabledPaymentMethods.includes("easebuzz") ||
                    enabledPaymentMethods.includes("quikwallet"));

                setShowSbi(enabledPaymentMethods.includes("sbiepay"));
                setShowBillDesk(enabledPaymentMethods.includes("billdesk"));
                setShowEaseBuzz(enabledPaymentMethods.includes("easebuzz"));
                setShowQuickwallet(enabledPaymentMethods.includes("quikwallet"));
                setShowCsc(enabledPaymentMethods.includes("csc"));

            }
            if (disabledPaymentMethods !== null) {

                setShowChallan(!disabledPaymentMethods.includes("challan"));

                setShowOnlinePayment(!disabledPaymentMethods.includes("sbiepay") ||
                    !disabledPaymentMethods.includes("billdesk") ||
                    !disabledPaymentMethods.includes("csc") ||
                    !disabledPaymentMethods.includes("quikwallet") ||
                    !disabledPaymentMethods.includes("easebuzz"));

                setShowSbi(!disabledPaymentMethods.includes("sbiepay"));
                setShowBillDesk(!disabledPaymentMethods.includes("billdesk"));
                setShowEaseBuzz(!disabledPaymentMethods.includes("easebuzz"));
                setShowQuickwallet(!disabledPaymentMethods.includes("quikwallet"));
                setShowCsc(!disabledPaymentMethods.includes("csc"));

            }
        }
    };

    const onApply = () => {
        applyInfo();
    };

    const goBack = () => {
        setSelectedExamCenters('')
        setDeclAccepted(false)
        setLocation('')
        setFlgDisabilityCompTime('')
        setFlgDisabilityScribeReq('')
        setScribeArrangedBy('')
        setIsNext(false);
        dispatch(resetUploadeDocumentChecks())
    }

    const movePostPrefUp = (index, eligibilityResultDtos) => {
        if(hasExamObject(applicationPostPrefList)){
            if (index == 0) return;
            const tmp = eligibilityResultDtos[index];
            eligibilityResultDtos[index] = eligibilityResultDtos[index - 1];
            eligibilityResultDtos[index - 1] = tmp;
            setDummy(dummy + 1);
        }else{
            if (index == 0) return;
            const tmp = applicationPostPrefList[index];
            applicationPostPrefList[index] = applicationPostPrefList[index - 1];
            applicationPostPrefList[index - 1] = tmp;
            setDummy(dummy + 1);
        }
        
    }

    const movePostPrefDown = (index, eligibilityResultDtos) => {
        if(hasExamObject(applicationPostPrefList)){
            if (index >= (eligibilityResultDtos.length)) return;
            const tmp = eligibilityResultDtos[index];
            eligibilityResultDtos[index] = eligibilityResultDtos[index + 1];
            eligibilityResultDtos[index + 1] = tmp;
            setDummy(dummy + 1);
        }else{
            if (index >= (applicationPostPrefList.length)) return;
            const tmp = applicationPostPrefList[index];
            applicationPostPrefList[index] = applicationPostPrefList[index + 1];
            applicationPostPrefList[index + 1] = tmp;
            setDummy(dummy + 1);
        }
        
    };

    const resetPref = () => {
        dispatch(resetApplicationPostPref());
        setSelectedDepartmentArr([]);
        setSomeOptionsThisPost([]);
    };

    const onChangeDocument = (event, doc) => {
        let post = applicationPostPrefList
            .filter(pref => pref.isInterested)
            .map((pref, index) => ({ ...pref, prefOrder: index + 1 }))
        if (event.target.files.length == 0) {
            return
        }
        let requestBody = {}
        requestBody.uploadPdfDoc = event.target.files[0]
        requestBody.documentTypeId = doc.documentTypeId
        dispatch(postUploadDocument(requestBody, advertisementId, post))
    }

    const viewDocument = (doc) => {
        dispatch(downloadDocument(doc.documentInfoId, doc.documentTypeName))
    }

    const handleDocumentListShow = (docList) => {
        setshowDocListModal(true)
        setSingleDocList(docList)
    };

    const processToPayment = (paymentData, paymenttype) => {
        let data = {}
        data.paymentBank = watch("paymentBank")
        if (paymenttype) {
            dispatch(doPayment(paymentData.applicationId, paymenttype, data))
        }
        dispatch(closePaymentModal(false))
    }

    const handleOptionsChange = (value, postPrefId) => {

        const tempArr = [];
        if (value.length > 0) {
            setSelectedOptionErr(true);
            value.map((e) => {
                if (e.id) {
                    tempArr.push(e.id);
                }
            });

            let alreadyArray = selectedTempDepartmentArr && selectedTempDepartmentArr.filter((eleN) => eleN.pid == postPrefId);
            if (alreadyArray.length > 0) {
                let newArray = [];
                selectedTempDepartmentArr && selectedTempDepartmentArr.length > 0 && selectedTempDepartmentArr.map((eleNN, index) => {
                    if (eleNN.pid == postPrefId) {
                        let arrDep = {
                            'pid': eleNN.pid,
                            'selectedDepartment': value
                        };

                        newArray.push(arrDep);
                    }
                    else {
                        newArray.push(eleNN);
                    }
                })

                setSelectedTempDepartmentArr(newArray);
            }
            else {
                let arrDep = {
                    'pid': postPrefId,
                    'selectedDepartment': value
                };
                setSelectedTempDepartmentArr((arr) => [...arr, arrDep]);
            }

            applicationPostPrefList && applicationPostPrefList.map((e) => {
                if (e.postCriteriaId === postPrefId) {
                    e.preferredAppointingAuthorities = JSON.stringify(tempArr);
                } else {
                    e.preferredAppointingAuthorities = null;
                }
            })

            setSomeOptionsThisPost((arr) => [...arr, postPrefId]);
            setReloadItem(!reloadItem);
            if (value.length > 0) {
                setSelectedOptionErr(true);
            } else {
                setSelectedOptionErr(false);
            }

        }
        else {
            setSelectedOptionErr(false);
            let newOption = someOptionsThisPost && someOptionsThisPost.filter((eleN) => eleN != postPrefId);;
            let newArray = selectedTempDepartmentArr && selectedTempDepartmentArr.filter((eleN) => eleN.pid != postPrefId);

            setSomeOptionsThisPost(newOption);

            setSelectedTempDepartmentArr(newArray);
            setReloadItem(!reloadItem);
        }

        deptOptions.filter((ele) => ele.pid == postPrefId).map((elem, index) => {

            if (elem.pid == postPrefId) {

                if (elem.options.isMultiple === true && elem.options.isSelectAny === false) {
                    if (value.length > 0) {
                        const upatedVal = value;
                        upatedVal.map((e) => {
                            (elem.options.mumbai).map((ele, index) => {


                                if (ele.id === e.id && ele.isDisable === false) {

                                    (elem.options.outsideMumbai).map((ele) => {
                                        ele.isDisable = true;

                                    });
                                }
                            });
                            (elem.options.outsideMumbai).map((ele) => {
                                if (ele.id === e.id && ele.isDisable === false) {


                                    (elem.options.mumbai).map((ele) => {
                                        ele.isDisable = true;
                                    });
                                }
                            });
                        })
                    } else {
                        (elem.options.mumbai).map((ele) => {
                            ele.isDisable = false;
                        });
                        (elem.options.outsideMumbai).map((ele) => {
                            ele.isDisable = false;
                        });
                    }
                }
                else if (elem.options.isMultiple === false && elem.options.isSelectAny === true) {
                    if (value.length > 0) {
                        const upatedVal = value;
                        upatedVal.map((e) => {
                            (elem.options.mumbai).map((ele) => {
                                if (ele.id === e.id && ele.isDisable === false) {

                                    (elem.options.outsideMumbai).map((ele) => {
                                        ele.isDisable = true;
                                    });
                                } else {
                                    ele.isDisable = true;
                                }
                            });
                            (elem.options.outsideMumbai).map((ele) => {
                                if (ele.id === e.id && ele.isDisable === false) {

                                    (elem.options.mumbai).map((ele) => {
                                        ele.isDisable = true;
                                    });
                                } else {
                                    ele.isDisable = true;
                                }
                            });
                        })
                    } else {
                        (elem.options.mumbai).map((ele) => {
                            ele.isDisable = false;
                        });
                        (elem.options.outsideMumbai).map((ele) => {
                            ele.isDisable = false;
                        });
                    };
                }
            }
        })
        if (tempArr.length <= 0) {
            toastr.error(advertisementDetailsLabel.PLEASE_SELECT_APPOINTING_AUTHORITY);
            setSelectedOptionErr(false);
        }
    };

    useEffect(() => {
        setSelectedDepartmentArr(selectedTempDepartmentArr);
    }, [selectedTempDepartmentArr]);

    const handleMenuClick = () => {
        if (allDepartments.length === 0) {
            dispatch(showLoader())
        } else {
            dispatch(hideLoader())
        }
    };

    const showReviewDepartmentModal = (postCriteriaId) => {
        setOpenModelPostid(postCriteriaId);
        setReviewDepartmentModal(true);
        setReloadItem(!reloadItem);
    }

    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
        </div>
    );

    const hasExamObject = (arr) => {
        if (arr && arr.length > 0 && arr[0].hasOwnProperty('exam')) {
            return true; 
        }
        return false; 
    }

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#007bff',
        fontWeight: '600',
    };

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    return (
        <div className="advertisement_container mb-4">
            <div className="row d-flex justify-content-center">
                <div className="col-md-9" >
                    <div className="row mar_LeftRight_0" >
                        <div className="col-md-8 advertisement_details_firstrow border border-light">
                            <div className="row">
                                <div className="col-md-6 fw-bold" >{advertisementDetailsLabel.Advertisement_No} {advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement ? advertisementDetailsInfo.data.advertisement.advertisementNo : ""}</div>
                            </div><hr className="my-2" />
                            <span className="fw-bold">{advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement ? advertisementDetailsInfo.data.advertisement.name : ""}  | {advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement ? advertisementDetailsInfo.data.advertisement.nameDvng : ""} </span><br />
                            {advertisementDetailsInfo?.data?.examPostList && advertisementDetailsInfo?.data?.examPostList.length>0 &&<span className="fw-bold"> {advertisementDetailsLabel.EXAMINATION} {advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement && advertisementDetailsInfo.data.advertisement.exam ? advertisementDetailsInfo.data.advertisement.exam.name : ""}</span>}<br /><br />
                        </div>
                        <div className="col-md-4 advertisement_details_firstrow border border-light">
                            <div className="d-flex justify-content-between">
                                <span className="fw-bold">{advertisementDetailsLabel.Start_Date_Application}</span>
                                <span>{advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement && advertisementDetailsInfo.data.advertisement.applnStartDate
                                    ? dayjs(advertisementDetailsInfo.data.advertisement.applnStartDate).format(commonLabels.DATE_FORMAT)
                                    : ""}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <span className="fw-bold">{advertisementDetailsLabel.Last_Date_Application}</span>
                                <span>{advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement && advertisementDetailsInfo.data.advertisement.applnEndDate
                                    ? dayjs(advertisementDetailsInfo.data.advertisement.applnEndDate).format(commonLabels.DATE_FORMAT)
                                    : ""}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <span className="fw-bold">{advertisementDetailsLabel.Last_Date_Fees}</span>
                                <span>{advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement && advertisementDetailsInfo.data.advertisement.challanPaymentEndDate
                                    ? dayjs(advertisementDetailsInfo.data.advertisement.challanPaymentEndDate).format(commonLabels.DATE_FORMAT)
                                    : ""}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <span className="fw-bold">{advertisementDetailsLabel.Fees_Applicable}</span>
                                <span>{applicationFee}</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="advertisement_details_Eligibility border border-light">
                        <label className="fw-bold">{advertisementDetailsLabel.POST}</label>
                        <button
                            type="button"
                            className="btn btn-primary float-end mt7"
                            onClick={() => {
                                checkEligibility();
                            }}
                            disabled={setCheckEligibilty === false ? eligibilityResultList !== null : true}
                        >
                            {common.ELIGIBILITY}
                        </button>
                        <hr />
                        {advertisementDetailsInfo?.data?.examPostList?.map((examPost) => (
                            <div key={examPost.id} className="card mt-2">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span>{examPost?.postCriteria?.name}</span>
                                        </div>
                                        <div className="col-md-6">
                                            {getElibilityIndicator(examPost?.postCriteria?.id)}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pre-wrap">{examPost?.postCriteria?.description}</div>
                            </div>
                        ))}
                        
                    </div> */}
                    
                    {advertisementDetailsInfo?.data?.examPostList && advertisementDetailsInfo?.data?.examPostList.length>0 &&
                    <div className="advertisement_details_Eligibility border border-light">
                        <label className="fw-bold">{advertisementDetailsLabel.POST}</label>
                        <button
                            type="button"
                            className="btn btn-primary float-end mt7"
                            onClick={() => {
                                checkEligibility();
                            }}
                            disabled={setCheckEligibilty === false ? eligibilityResultList !== null : true}
                        >
                            {common.ELIGIBILITY}
                        </button>
                        <hr />
                        {advertisementDetailsInfo?.data?.examPostList?.map((examPost) => (
                            <div key={examPost.id} className="card mt-2">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span>{examPost?.postCriteria?.name}</span>
                                        </div>
                                        <div className="col-md-6">
                                            {getElibilityIndicator(examPost?.postCriteria?.id)}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pre-wrap">{examPost?.postCriteria?.description}</div>
                            </div>
                        ))}
                        
                    </div>}

{/* ----------------------------------------------------------------------------------------------- */}
                    {advertisementDetailsInfo && advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisementExamPostDtos && advertisementDetailsInfo.data.advertisementExamPostDtos.length>0 && 
                        <button
                            type="button"
                            className="btn btn-primary float-end mt-7"
                            onClick={() => {
                                checkEligibility();
                            }}
                            disabled={setCheckEligibilty === false ? eligibilityResultList !== null : true}
                        >
                            {common.ELIGIBILITY}
                        </button>}
                    {advertisementDetailsInfo && advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisementExamPostDtos && advertisementDetailsInfo.data.advertisementExamPostDtos.length>0 && advertisementDetailsInfo.data.advertisementExamPostDtos.map((exam, index)=>{
                        return <div className={`advertisement_details_Eligibility border border-light ${index === 0 && 'mt-5'}`} key={index}>
                            <label className="fw-bold">{advertisementDetailsLabel.EXAMINATION}</label>&nbsp;<span>{exam?.exam?.name}</span>
                            
                            <hr />
                            <label className="fw-bold">{advertisementDetailsLabel.POST} :</label>
                            {exam.examPostList && exam.examPostList && exam.examPostList.length > 0 && exam.examPostList?.map((examPost) => (
                                <div key={examPost.id} className="card mt-2">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <span>{examPost?.postCriteria?.name}</span>
                                            </div>
                                            <div className="col-md-6">
                                                {getMultiExamElibilityIndicator(examPost?.postCriteria?.id, exam.exam.id)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pre-wrap">{examPost?.postCriteria?.description}</div>
                                </div>
                            ))}
                        </div>
                    })}

                    <div >
                        {(eligibilityResultList != null && applicationPostPrefList?.length != 0) && applicableAdditionalQuestionsList &&
                            applicableAdditionalQuestionsList?.length != 0 && <QuestionDetails additionalAnswers={additionalAnswers} handleQuestions={handleQuestions} isNext={isNext} />}
                    </div>
                    <div >
                        <ProfileDetails />
                    </div>
                    <div className="advertisement_details_Eligibility border border-light">
                        <label className="fw-bold">{advertisementDetailsLabel.INSTRUCTIONS}</label>
                        <hr />
                        <div className="card">
                            <div className="card-body pad_0">
                                <ul className="list-group list-group-flush">
                                    {advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisementInstructionList.map((ele, index) =>
                                        <li className="list-group-item" key={index}>
                                            <span>{ele.instruction}</span>
                                            <span className="float-end">{ele.instructionDvng}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="card mt-3">
                        <div className="card-body">
                            <span>{advertisementDetailsLabel.Before_Applying_PDF}</span>
                            <div>
                                <button type="button" className="btn btn-primary m-1" onClick={() => { downloadPdfFile() }} >{common.Download_PDF}</button>
                            </div>
                        </div>
                    </div>

                    {/* old select and apply */}
                    {( eligibilityResultList != null && applicationPostPrefList?.length != 0 && (!hasExamObject(applicationPostPrefList))) &&
                        <>
                            <div className="card mt-2">
                                <div className="card-header">
                                    <span className="fw-bold">{advertisementDetailsLabel.Select_and_apply}</span><br />
                                    <hr className="mar_top_bottom_8" />
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <span>{advertisementDetailsLabel.EligibleFollowingPost}</span>
                                    </li>
                                    {applicationPostPrefList?.map((postPref, index) =>
                                        <div key={index}>
                                            <li  className="list-group-item post-pref-item d-flex" >
                                                <div className="flex-grow-1">
                                                    <input className="d-inline-block" type="checkbox" name="isInterested"
                                                        checked={postPref.isInterested} onChange={(event) => handlePrefChange(postPref, 'isInterested', event.target.checked, postPref.postCriteriaId)} disabled={isNext} /> &nbsp;
                                                    <span >{getPostName(postPref.postCriteriaId)}</span> <br /> &nbsp; &nbsp;  &nbsp;
                                                    <span className="w65">{getPostName(postPref.postCriteriaId, true)}</span>
                                                </div>
                                                <div className="arrange-arrows d-flex flex-column justify-content-between" >
                                                    <div>
                                                        {(index > 0) &&
                                                            <i className="fa fa-arrow-up"
                                                                onClick={() => movePostPrefUp(index)} mtitle="Move Up"></i>
                                                        }
                                                    </div>
                                                    <div>
                                                        {(index < (applicationPostPrefList.length - 1)) &&
                                                            <i className="fa fa-arrow-down"
                                                                onClick={() => movePostPrefDown(index)} title="Move Down"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                            {(postPref.isInterested === true && postPref.isAssignAuthority === true) &&
                                                <div className="card-body">
                                                    <div className="pad_0 btn-dropdown">
                                                        <Select
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            openMenuOnClick={()=>handleMenuClick()}
                                                            placeholder={advertisementDetailsLabel.APPOINTING_AUTHORITIES}
                                                            onChange={(options) => { handleOptionsChange(options, postPref.postCriteriaId) }}
                                                            formatGroupLabel={formatGroupLabel}
                                                            options={
                                                                [
                                                                    { label: advertisementDetailsLabel.MUMBAI_APPOINTING_AUTHORITIES, options: deptOptions.filter((ele) => ele.pid == postPref.postCriteriaId).map((elem) => elem.options.mumbai)[0] },
                                                                    { label: advertisementDetailsLabel.OTHER_THAN_MUMBAI_APPOINTING_AUTHORITIES, options: deptOptions.filter((ele) => ele.pid == postPref.postCriteriaId).map((elem) => elem.options.outsideMumbai)[0] }
                                                                ]
                                                            }
                                                            controlShouldRenderValue={false}
                                                            isOptionDisabled={(option) => option.isDisable}
                                                            components={{
                                                                Option: InputOption
                                                            }}
                                                        />
                                                        {((someOptionsThisPost && someOptionsThisPost.indexOf(postPref.postCriteriaId) == -1)) ? <p className="errorText">{advertisementDetailsLabel.PLEASE_SELECT_APPOINTING_AUTHORITY}</p> : ""}
                                                    </div>
                                                    <button type="button" className="btn btn-primary button_reset_apply review_btn_css" onClick={() => showReviewDepartmentModal(postPref.postCriteriaId)} >{advertisementDetailsLabel.REVIEW_SELECTED_APPOINTING_AUTHORITIES}</button>&nbsp;
                                                </div>}
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </>
                    }

                    {/* new multiple exam select and apply */}
                    {(eligibilityResultList != null && applicationPostPrefList &&  applicationPostPrefList?.length != 0 && hasExamObject(applicationPostPrefList)) &&
                        applicationPostPrefList.map((exam , index) => {
                                return <div className="card mt-2" key={index}>
                                <div className="card-header">
                                    {/* --------------------------exam selection----------- */}
                                    <div className="flex-grow-1">
                                        <input 
                                            className="d-inline-block" type="checkbox" name="isInterested"
                                            checked={exam.eligibilityResultDtos && exam.eligibilityResultDtos.length >0 && exam.eligibilityResultDtos.every(record => record.isInterested === true)}
                                            onChange={(event) => handleExamSelection('isInterested', event.target.checked, exam.exam.id, exam)} 
                                            disabled={isNext} 
                                        /> &nbsp;
                                        <span className="fw-bold">{exam.exam.name}</span><br />
                                    </div>
                                    <hr className="mar_top_bottom_8" />
                                    <span className="fw-bold">{advertisementDetailsLabel.Select_and_apply}</span><br />
                                </div>

                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <span>{advertisementDetailsLabel.EligibleFollowingPost}</span>
                                    </li>
                                    {exam.eligibilityResultDtos?.map((postPref, index) =>
                                        <div key={index}>
                                            <li  className="list-group-item post-pref-item d-flex" >
                                                <div className="flex-grow-1">
                                                    <input className="d-inline-block" type="checkbox" name="isInterested"
                                                        checked={postPref.isInterested} onChange={(event) => handlePrefChange(postPref, 'isInterested', event.target.checked, postPref.postCriteriaId)} disabled={isNext} /> &nbsp;
                                                    <span >{getPostName(postPref.postCriteriaId, false, exam.exam.id)}</span> <br /> &nbsp; &nbsp;  &nbsp;
                                                    <span className="w65">{getPostName(postPref.postCriteriaId, true, exam.exam.id)}</span>
                                                </div>
                                                <div className="arrange-arrows d-flex flex-column justify-content-between" >
                                                    <div>
                                                        {(index > 0) &&
                                                            <i className="fa fa-arrow-up"
                                                                onClick={() => movePostPrefUp(index, exam.eligibilityResultDtos)} mtitle="Move Up"></i>
                                                        }
                                                    </div>
                                                    <div>
                                                        {(index < (exam.eligibilityResultDtos.length - 1)) &&
                                                            <i className="fa fa-arrow-down"
                                                                onClick={() => movePostPrefDown(index, exam.eligibilityResultDtos)} title="Move Down"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </li>

                                            {(postPref.isInterested === true && postPref.isAssignAuthority === true) &&
                                                <div className="card-body">
                                                    <div className="pad_0 btn-dropdown">
                                                        <Select
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            openMenuOnClick={()=>handleMenuClick()}
                                                            placeholder={advertisementDetailsLabel.APPOINTING_AUTHORITIES}
                                                            onChange={(options) => { handleOptionsChange(options, postPref.postCriteriaId) }}
                                                            formatGroupLabel={formatGroupLabel}
                                                            options={
                                                                [
                                                                    { label: advertisementDetailsLabel.MUMBAI_APPOINTING_AUTHORITIES, options: deptOptions.filter((ele) => ele.pid == postPref.postCriteriaId).map((elem) => elem.options.mumbai)[0] },
                                                                    { label: advertisementDetailsLabel.OTHER_THAN_MUMBAI_APPOINTING_AUTHORITIES, options: deptOptions.filter((ele) => ele.pid == postPref.postCriteriaId).map((elem) => elem.options.outsideMumbai)[0] }
                                                                ]
                                                            }
                                                            controlShouldRenderValue={false}
                                                            isOptionDisabled={(option) => option.isDisable}
                                                            components={{
                                                                Option: InputOption
                                                            }}
                                                        />
                                                        
                                                        {((someOptionsThisPost && someOptionsThisPost.indexOf(postPref.postCriteriaId) == -1)) ? <p className="errorText">{advertisementDetailsLabel.PLEASE_SELECT_APPOINTING_AUTHORITY}</p> : ""}
                                                    </div>
                                                    <button type="button" className="btn btn-primary button_reset_apply review_btn_css" onClick={() => showReviewDepartmentModal(postPref.postCriteriaId)} >{advertisementDetailsLabel.REVIEW_SELECTED_APPOINTING_AUTHORITIES}</button>&nbsp;
                                                </div>}
                                        </div>
                                    )}
                                </ul>
                            </div>
                        })
                            
                        
                    }
                    {(eligibilityResultList != null && applicationPostPrefList?.length == 0) &&
                        <div className="card mt-2">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span>{advertisementDetailsLabel.YOU_ARE_NOT_ELIGIBLE_FOR_ANY_POST}</span>
                                </li>
                            </ul>
                        </div>
                    }
                    {(eligibilityResultList != null && applicationPostPrefList?.length != 0 && !isNext) &&
                        <>
                            <button type="button" className="btn btn-outline-dark button_reset_apply" onClick={resetPref} >{common.RESET}</button>&nbsp;
                            <button type="button" className="btn btn-primary btn button_reset_apply" disabled={selectedOptionErr === false ? true : false} onClick={doNext} >{common.APPLY}</button>
                        </>
                    }
                    {isNext &&
                        <>
                            {uploadedDocumentData && uploadedDocumentData.length > 0 ?
                                <div className="card mt-2">
                                    <div className="card-header pe-0" >
                                        <span className="fw-bold">{advertisementDetailsLabel.Upload_select_documents} <span className="redColor">*</span></span>
                                        <hr className="mar_top_bottom_8" />
                                        <span className="noteColor">{advertisementDetailsLabel.CONFIRM_UPLOAD_DOCUMENT_NOTE} </span>
                                    </div>
                                    
                                    <div className="card-body">
                                        <div className="pad_0 btn-dropdown">
                                            {uploadedDocumentData && uploadedDocumentData.map((doc, i) => (
                                                <ul key={i} className="list-group">
                                                    <li className="list-group-item post-pref-item d-flex">
                                                        <div className="flex-grow-1">
                                                            <input
                                                                className="d-inline-block me-1"
                                                                type="checkbox"
                                                                name="isUploaded"
                                                                checked={doc.isUploaded}
                                                                onChange={(event) => handleDocChange(doc, 'isUploaded', event.target.checked)}
                                                                disabled={!doc.documentInfoId}
                                                            />
                                                            &nbsp;
                                                            <i
                                                                className="fa fa-info-circle document_list_css"
                                                                data-toggle="tooltip"
                                                                title="Document List"
                                                                aria-hidden="true"
                                                                onClick={() => handleDocumentListShow(doc.documentTypeDescription)}>
                                                            </i>
                                                            <span>{doc.documentTypeName}</span>

                                                            <div className="float-end">
                                                                <input
                                                                    type="file"
                                                                    name="document"
                                                                    id={"document" + i}
                                                                    onChange={(event) => onChangeDocument(event, doc)}
                                                                    className="d-none"
                                                                    accept="application/pdf"
                                                                />
                                                                {doc.documentInfoId ? (
                                                                    <label htmlFor={"document" + i} className="pointer-class">
                                                                        {advertisementDetailsLabel.RE_UPLOAD}
                                                                    </label>
                                                                ) : (
                                                                    <label htmlFor={"document" + i} className="pointer-class">
                                                                        {advertisementDetailsLabel.UPLOAD}
                                                                    </label>
                                                                )}
                                                            </div>
                                                            <br />
                                                            <div>
                                                                {doc.documentInfoId ? (
                                                                    <button
                                                                        type="button"
                                                                        className="pointer-class mt-2"
                                                                        onClick={() => viewDocument(doc)}
                                                                    >
                                                                        {advertisementDetailsLabel.VIEW_UPLOADED_DOCUMENT}
                                                                    </button>
                                                                ) : (
                                                                    <button type="button" className="text-danger ml37">
                                                                        {advertisementDetailsLabel.NOT_UPLOADED}
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            ))}
                                        </div>
                                    </div>

                                </div> : ""}

                                {/* subject selection */}
                                
                            {applicationPostPrefList &&  applicationPostPrefList?.length != 0 && hasExamObject(applicationPostPrefList) && applicationPostPrefList.map((postPref, i)=>{
                                if(postPref.eligibilityResultDtos.length> 0 && postPref.eligibilityResultDtos.some(post=>post.isInterested ==true)){  
                                    if(postPref.exam.optionalSubjectCount === 0){
                                        return 
                                    }                                  
                                    return <div className="card mt-2" key={i}>
                                        <div className="card-header">
                                            <span className="fw-bold">{postPref.exam.name} </span>
                                            
                                        </div>
                                        <div className="card-body p-1">
                                            {Array.from({ length: postPref.exam.optionalSubjectCount ? postPref.exam.optionalSubjectCount : 0 }, (_, index) => index + 1)
                                            .map((number,index) => {
                                                return <div className={`card p-1 ${index!==0 && 'mt-1'}`} key={index}>
                                                <label>
                                                    {advertisementDetailsLabel.SELECT_SUBJECT}{" "+number} <span className="redColor">*</span>
                                                </label>
                                                <select 
                                                    className="form-select" 
                                                    value={(selectedSubjects && selectedSubjects[postPref.exam.id] && selectedSubjects[postPref.exam.id][index] && selectedSubjects[postPref.exam.id][index]) || ''}
                                                    
                                                    onChange={(event) => {
                                                            if(selectedSubjects && selectedSubjects[postPref.exam.id] && selectedSubjects[postPref.exam.id].some(value=> value==event.target.value)){
                                                                
                                                                toastr.warning(advertisementDetailsLabel.PLEASE_SELECT_ANOTHER_SUBJECT)
                                                                
                                                            }else{
                                                               let isSameSubWithSameGroup = Array.isArray(selectedSubjects[postPref.exam.id]) && selectedSubjects[postPref.exam.id].some((subjectId, subInd)=>{
                                                                    let noToVerify = parseInt(event.target.value)
                                                                    if(index !== subInd){
                                                                        if(subjectMedium[postPref.exam.id][subjectId]?.subjectAllowedList.includes(noToVerify) ){
                                                                            return true
                                                                        }else if(subjectMedium[postPref.exam.id][subjectId]?.subjectNotAllowedList.includes(noToVerify) ){
                                                                            return false
                                                                        }else{
                                                                            return true
                                                                        }
                                                                    }else {
                                                                        true
                                                                    }
                                                                })
                                                                
                                                                if(Array.isArray(selectedSubjects[postPref.exam.id]) && postPref.exam.optionalSubjectCount>1 && selectedSubjects[postPref.exam.id].length>0 && isSameSubWithSameGroup == false && event.target.value!==''){
                                                                    
                                                                    toastr.warning(advertisementDetailsLabel.DIFFERENT_GROUP_SUBJECT_NOT_ALLOWD)
                                                                }
                                                                // else if(isSameSubWithSameGroup && event.target.value!==''){
                                                                //     toastr.warning(advertisementDetailsLabel.SAME_GROUP_SUBJECT_NOT_ALLOWD)
                                                                // }
                                                                else{
                                                                    let subConfig = [...examSubjectConfig];
                                                                    let modifiedData = subConfig.length>0 && subConfig.map((exam) => {
                                                                        
                                                                        if(exam.examId==postPref.exam.id){
                                                                            let allSubjects = [...exam.subjects]
                                                                            if(event.target.value == ''){
                                                                                allSubjects.splice(index,1)
                                                                            }else{
                                                                                allSubjects[index] = {subjectId : parseInt(event.target.value) ? parseInt(event.target.value) : event.target.value}
                                                                            }
                                                                            return {
                                                                                ...exam,
                                                                                subjects: allSubjects, 
                                                                                optionalSubjectCount: postPref.exam.optionalSubjectCount
                                                                            }
                                                                        }else{
                                                                            return exam
                                                                        }
                                                                    })
                                                                    setSelectedSubjects(prevState => {
                                                                        const updatedArray = [...(prevState[postPref.exam.id] || [])];
                                                                        if(event.target.value!==''){
                                                                            updatedArray[index] = event.target.value; 
                                                                        }else{
                                                                            updatedArray.splice(index,1)
                                                                        }
                                                                        return {
                                                                        ...prevState,
                                                                        [postPref.exam.id]: updatedArray
                                                                        };
                                                                    });
                                                                    setExamSubjectConfig(modifiedData)
                                                                    }
                                                                }
                                                                
                                                            
                                                        }
                                                    }

                                                >
                                                    <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                                                    {postPref.exam.examSubjectDto && postPref.exam.examSubjectDto.length > 0 && postPref.exam.examSubjectDto.map((ele, i) => {
                                                        return <option key={i} value={ele.id}>{ele.name}</option>
                                                    })}
                                                </select>
                                                <label>
                                                    {advertisementDetailsLabel.SELECT_MEDIUM} {number} <span className="redColor">*</span>
                                                </label>
                                                <select 
                                                    className="form-select" 
                                                    value={(examSubjectConfig.find(exam=>exam.examId===postPref.exam.id)?.subjects[index]?.mediumIds && examSubjectConfig.find(exam=>exam.examId==postPref.exam.id)?.subjects[index]?.mediumIds[0]) ? (examSubjectConfig.find(exam=>exam.examId===postPref.exam.id)?.subjects[index]?.mediumIds && examSubjectConfig.find(exam=>exam.examId==postPref.exam.id)?.subjects[index]?.mediumIds[0]): ''}
                                                    onChange={(event) => {
                                                        let subConfig= [...examSubjectConfig]
                                                        let modifiedData = subConfig.length > 0 && subConfig.map((exam, modindex) => {
                                                            if (exam.examId == postPref.exam.id) {
                                                                // Modify the subjects array
                                                                const updatedSubjects = exam.subjects.map(subject => {
                                                                    
                                                                    if (subject.subjectId == (selectedSubjects && selectedSubjects[postPref.exam.id] && selectedSubjects[postPref.exam.id][index] && selectedSubjects[postPref.exam.id][index])) {
                                                                        return {
                                                                            ...subject,
                                                                            mediumIds: [parseInt(event.target.value)]  
                                                                        };
                                                                    }
                                                                    return subject;  
                                                                });
                                                        
                                                                return {
                                                                    ...exam,
                                                                    subjects: updatedSubjects
                                                                };
                                                            } else {
                                                                return exam;
                                                            }
                                                        });
                                                        
                                                        setExamSubjectConfig(modifiedData)
                                                    }}
                                                >
                                                    <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                                                    

                                                    {subjectMedium[postPref.exam.id] && selectedSubjects[postPref.exam.id] && selectedSubjects[postPref.exam.id][index] && subjectMedium[postPref.exam.id][selectedSubjects[postPref.exam.id][index]].medium && Array.isArray(subjectMedium[postPref.exam.id][selectedSubjects[postPref.exam.id][index]].medium) 
                                                    && subjectMedium[postPref.exam.id][selectedSubjects[postPref.exam.id][index]].medium.map((ele, i) => {
                                                        return <option key={i} value={ele.id}>{ele.label}</option>
                                                    })}
                                                    
                                                </select>
                                                </div>;
                                            })}
                                            
                                        </div>
                                    </div>
                                }else{
                                    return
                                }
                            }) }
                            <div className="card mt-2">
                                <div className="card-header">
                                    <span className="fw-bold">{advertisementDetailsLabel.Select_exam_center} <span className="redColor">*</span></span>
                                    <br />{maximumNoCenter ? <span className="noteColor"> {advertisementDetailsLabel.SELECT_NOTE} {maximumNoCenter} {advertisementDetailsLabel.EXAM_CENTER} </span> : ""}
                                </div>
                                <div className="card-body">
                                    <div className="pad_0 btn-dropdown">
                                        <Select
                                            isMulti={true}
                                            isOptionDisabled={() => maximumNoCenter ? selectedExamCenters.length >= maximumNoCenter : false}
                                            value={selectedExamCenters}
                                            onChange={setSelectedExamCenters}
                                            options={examCenterOptionList.filter(examCenter => examCenter.isDeleted == false)} />
                                        {selectedExamCenters && selectedExamCenters.length >= 1 ? '' : <p className="errorText">{advertisementDetailsLabel.SELECT_OPTIONS}</p>}
                                    </div>
                                </div>
                            </div>
                            {profileInfo && profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDivyang && profileInfo.personalInfoDto.divyangPercentage >= 40 ?
                                <div className="card mt-2">
                                    <div className="card-header">
                                        <span className="fw-bold">{advertisementDetailsLabel.BENCHMARK_DISABILITIES_DETAILS}</span>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <label>
                                                {advertisementDetailsLabel.DISABILITY_COMP_TIME}
                                            </label>
                                            <select className="form-select" name="disabilityCompTime" value={flgDisabilityCompTime}
                                                onChange={(event) => setFlgDisabilityCompTime(event.target.value)}>
                                                <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                                                {BOOLEAN_OPTION.map((ele, i) => {
                                                    return <option key={i} value={ele.value}>{ele.label}</option>
                                                })}
                                            </select>
                                        </li>
                                        <li className="list-group-item">
                                            <label>
                                                {advertisementDetailsLabel.DISABILITY_SCRIBE_REQ}
                                            </label>
                                            <select className="form-select" name="disabilityScribeReq" value={flgDisabilityScribeReq}
                                                onChange={(event) => setFlgDisabilityScribeReq(event.target.value)}>
                                                <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                                                {BOOLEAN_OPTION.map((ele, i) => {
                                                    return <option key={i} value={ele.value}>{ele.label}</option>
                                                })}
                                            </select>
                                        </li>
                                        <li className="list-group-item">
                                            <label>
                                                {advertisementDetailsLabel.SCRIBE_ARRANGED_BY}
                                            </label>
                                            <select className="form-select" name="scribeArrangedBy" value={scribeArrangedBy}
                                                onChange={(event) => setScribeArrangedBy(event.target.value)}>
                                                <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                                                {SCRIBE_OPTION.map((ele, i) => {
                                                    return <option key={i} value={ele.value}>{ele.label}</option>
                                                })}
                                            </select>
                                        </li>
                                    </ul>
                                </div>
                                : ""}
                            <div className="card mt-2">
                                <div className="card-header">
                                    <span className="fw-bold">{advertisementDetailsLabel.Declaration} <span className="redColor">*</span></span>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <input type="checkbox" checked={declAccepted} onChange={(event) => setDeclAccepted(event.target.checked)} />&nbsp;
                                        I&nbsp;
                                        {profileInfo && profileInfo.personalInfo ?

                                            profileInfo.personalInfo.englishFullName
                                            :
                                            "CANDIDATE FULL NAME"
                                        }&nbsp;
                                        <span>{advertisementDetailsLabel.DECLARATION}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <div>{advertisementDetailsLabel.DATE} {dayjs().format(commonLabels.DATE_FORMAT)}</div>
                                        <div className="d-flex text-center">
                                            <span className="pe-2">{advertisementDetailsLabel.Location}&nbsp;<span className="redColor">*</span></span>
                                            <input type="text" className="form-control" value={location} onChange={(event) => setLocation(event.target.value)} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <img
                                            src={profileInfo && profileInfo.signature ? `data:image/png;base64,${profileInfo.signature}` : ""}
                                            width="130" height="60" />
                                    </li>
                                </ul>
                            </div>
                            <button type="button" className="btn btn-outline-dark btn-sm button_reset_apply" onClick={goBack} >{common.CANCEL}</button>&nbsp;
                            

                            
                            {hasExamObject(applicationPostPrefList) && <button 
                                type="button" className="btn btn-primary btn-sm button_reset_apply" 
                                onClick={onApply} 
                                disabled={!(declAccepted && location && selectedExamCenters.length > 0 && selectedOptionErr && (examSubjectConfig.every(item =>item.subjects.length == item.optionalSubjectCount && item.subjects.every(subject => subject.mediumIds && subject.mediumIds.length > 0))))}
                            >{common.SUBMIT_AND_FEES}</button>}
                            {!hasExamObject(applicationPostPrefList) &&<button 
                                type="button" className="btn btn-primary btn-sm button_reset_apply" 
                                onClick={onApply} 
                                disabled={!(declAccepted && location && selectedExamCenters.length > 0 && selectedOptionErr )}
                            >{common.SUBMIT_AND_FEES}</button>}
                        </>
                    }
                </div>

            </div>
            <Modal show={proceedToPayment} backdrop='static'>
                <form>
                    <Modal.Header >
                        <Modal.Title>{myAccountLabels.SELECT_PAYMENT_MODE}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row">
                            <div className="col-md-12">
                                <span>{advertisementDetailsLabel.APPLICATION_SAVE_NOTE}</span>  <br /><br />
                                {showChallan ?
                                    <div className="input-group">
                                        <label className="position-relative mb-1">
                                            <input type="radio" className="radio" name="paymenttype" value="challan_payment" disabled={challanDownloadDate && dayjs(challanDownloadDate).isBefore(new Date(), 'day')} ref={register({ required: true })} />&nbsp;{advertisementDetailsLabel.Challan_Payment}
                                        </label>
                                        <span className="ps-4">{advertisementDetailsLabel.DOWNLOAD_AND_PRINT_CHALLAN_NOTE}  &nbsp;
                                            {challanDownloadDate ? dayjs(challanDownloadDate).format(commonLabels.DATE_FORMAT) : ""}. {advertisementDetailsLabel.CHALLAN_PAYMENT_NOTE} &nbsp;{applyedApplication?.challanPaymentEndDate ? dayjs(applyedApplication.challanPaymentEndDate).format(commonLabels.DATE_FORMAT) : ""}.
                                        </span>
                                    </div> : ""}
                                <br />
                                {showOnlinePayment ?
                                    <div className="input-group">
                                        <label className="position-relative mb-1">
                                            <input type="radio" className="radio" name="paymenttype" value="online_payment" disabled={applyedApplication && dayjs(applyedApplication.onlinePaymentEndDate).isBefore(new Date(), 'day')} ref={register({ required: true })} />&nbsp;{advertisementDetailsLabel.Online_Payment}
                                        </label>
                                        <span className="ps-4">{advertisementDetailsLabel.ONLINE_PAYMENT_NOTE} &nbsp;
                                            {applyedApplication && applyedApplication.onlinePaymentEndDate ? dayjs(applyedApplication.onlinePaymentEndDate).format(commonLabels.DATE_FORMAT) : ""}
                                        </span>
                                    </div> : ""}
                                <br />
                                {watch("paymenttype") === 'online_payment' ?
                                    <div className="ps-4">
                                        {showSbi ?
                                            <div className="input-group">
                                                <label className="position-relative mb-2">
                                                    <input type="radio" className="radio" name="paymentBank" value="sbi" ref={register({ required: true })} />&nbsp;{commonLabels.SBI}
                                                </label>
                                            </div> : ""}
                                        {showBillDesk ?
                                            <div className="input-group">
                                                <label className="position-relative mb-2">
                                                    <input type="radio" className="radio" name="paymentBank" value="billdesk" ref={register({ required: true })} />&nbsp;{commonLabels.BILL_DESK}
                                                </label>
                                            </div> : ""}
                                        {showCsc ?
                                            <div className="input-group">
                                                <label className="position-relative mb-2">
                                                    <input type="radio" className="radio" name="paymentBank" value="csc" ref={register({ required: true })} />&nbsp;{commonLabels.CSC}
                                                </label>
                                            </div> : ""}
                                        {showEaseBuzz ?
                                            <div className="input-group">
                                                <label className="position-relative mb-2">
                                                    <input type="radio" className="radio" name="paymentBank" value="easebuzz" ref={register({ required: true })} />&nbsp;{commonLabels.EASEBUZZ}
                                                </label>
                                            </div> : ""}
                                        {showQuickwallet ?
                                            <div className="input-group">
                                                <label className="position-relative mb-2">
                                                    <input type="radio" className="radio" name="paymentBank" value="quickPayWallet" ref={register({ required: true })} />&nbsp;{commonLabels.QUICK_WALLET}
                                                </label>
                                            </div> : ""}
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-primary "
                            disabled={!formState.isValid} onClick={() => { processToPayment(applyedApplication, watch("paymenttype")) }}>{commonLabels.OK}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <DocumentList
                showModal={showDocListModal}
                setshowDocList={setshowDocListModal}
                docData={singleDocList}
            />
            {reviewDepartmentModal &&
                <ReviewSelectedModal
                    showModal={reviewDepartmentModal}
                    setReviewDepartmentModal={setReviewDepartmentModal}
                    currentPostId={currentPostId}
                    options={selectedDepartmentArr}
                />
            }
        </div>

    )
}

export default AdvertisementDetails;