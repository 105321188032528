/* eslint-disable */
import React, { useEffect } from 'react';
// import API from '../../helpers/API';
import API, { axiosInstanceWithoutEncrDecr } from '../../helpers/API';

import { useDispatch } from "react-redux";
import Login from '../../registration_login/components/Login';
import { SESSION_HEARTBEAT } from '../constants/endpoints';
import CandidateNav from './CandiateNav';
import SecurityQuestion from '../../registration_login/components/SecurityQuestion';
import { ceckFieldChanges, checkModuleEnableForTabs} from "../../registration_login/actions/sessionActions";
import { useNavigate } from 'react-router-dom';

const CandidateContainer = (props) => {
    const navigate = useNavigate();
    const session = JSON.parse(sessionStorage.getItem("session"));
    const sessionHeartBeatInterval = process.env.REACT_APP_SESSION_HB_INTERVAL || 5
    ;
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        dispatch(checkModuleEnableForTabs());
        dispatch(ceckFieldChanges());
    }, []);

    setInterval(() => {
        if (session && session.jwtResponse.token) {
            axiosInstanceWithoutEncrDecr.patch(SESSION_HEARTBEAT + `/${session.userId}`, {}, { headers: { 'Authorization': `Bearer ${session.jwtResponse.token}` } });
        }
    }, sessionHeartBeatInterval * 60 * 1000);
        
    if(session && session.jwtResponse.token) {
        return session.securityAnswer ? <CandidateNav {...props}/> : <SecurityQuestion {...props}/>
    }
    // props.history.push("/candidate/login");
    navigate("/candidate/login")
    return <Login />
};

export default CandidateContainer;
