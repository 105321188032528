/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import "../../styles/candidate_nav.css";
import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash/fp";
import ModuleHide from "../../common/components/ModuleHide";
import commonLabels from "../../my_account/labels/common_en.json";
import "../../styles/home.css";

export default function CandidateHome() {
    const { moduleList, changedFieldsList } = useSelector(state => state.loginReducer, shallowEqual);
     const [arraymModule , setarraymModule] = useState({})
    const [showModal, setShowModal] = useState(false);


    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };

    useEffect(()=>{
        setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "HOME_TAB")[0])
    },[moduleList])

    useEffect(()=>{
        if(changedFieldsList){
            changedFieldsList.length>0 && setShowModal(true)
        }
    },[changedFieldsList])
    
    let squarebullet = "◾"
    
    return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
    (
        <div>
            <div className="container">
                <div className="row card d-flex mb-4 mt-4">
                    <div className="col-md-12 border border-light bg-white">
                        <p className="text-start ms-4">{commonLabels.HOME_NOTE_1}</p>

                        <p className="borderBottomCss mx-4"></p>
                        <h5 className="text-start ms-4 mt-2">{commonLabels.HOME_NOTE_2}</h5>
                        <p className="borderBottomCss m-4"></p>
                        
                        <p className="text-start ms-4 mt-4">{commonLabels.HOME_NOTE_3}</p>
                        <p className="text-start ml60">{commonLabels.HOME_NOTE_3_1}</p>
                        <p className="text-start ml60">{commonLabels.HOME_NOTE_3_2}</p>
                        <p className="text-start ml60">{commonLabels.HOME_NOTE_3_3}</p>
                        <p className="text-start ml60">{commonLabels.HOME_NOTE_3_4}</p>
                        <p className="text-start ml60">{commonLabels.HOME_NOTE_3_5}</p>
                        <p className="text-start ms-4">{commonLabels.HOME_NOTE_4}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_4_1} </p>
                        <p className="text-start ms-4">{commonLabels.HOME_NOTE_5}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_1} </p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_2} </p>
                        <p className="text-start ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_1}</p>
                        <p className="text-start ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_2}</p>
                        <p className="text-start ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_3}</p>
                        <p className="text-start ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_4}</p>
                        <p className="text-start ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_5}</p>
                        <p className="text-start ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_6}</p>
                        
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_3}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_4}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_5}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_6}</p>
                        
                        <p className="text-start ms-4">{commonLabels.HOME_NOTE_6}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_1}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_2}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_3}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_4}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_5}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_6}</p>

                        <p className="text-start ms-4">{commonLabels.HOME_NOTE_7}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_7_1}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_7_2}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_7_3}</p>
                        <p className="text-start ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_7_4}</p>
                    </div>
                </div>
            </div>

        <Modal  size="xl"  show={showModal} onHide={()=>setShowModal(false)} backdrop='static' scrollable>
          <Modal.Header className="d-flex justify-content-between align-items-center">
            
                <h4>{commonLabels.IMPORTANT_NOTE}</h4>
                <button onClick={()=>setShowModal(false)} type="button" class="h4 mb-0 float-end"><span aria-hidden="true">×</span>
                {/* <span class="sr-only">{commonLabels.CLOSE}</span> */}
                </button>
            
          </Modal.Header>
          <Modal.Body>

            <p className="panMessage mt-2">
                {commonLabels.DEAR_CANDIDATE}
                {commonLabels.PLEASE_UPDATE_PROFILE_CONTENT}
            </p>
            <p className="mt-1">
            {changedFieldsList && changedFieldsList.length>0 && changedFieldsList.map((tabSection)=>{
                return <>{`${tabSection.className} [ ${tabSection.newFields && tabSection.newFields.length>0 &&tabSection.newFields.map((field,index,fieldlist)=> {  return `${index==0? field :' '+field}`})}]` }<br></br></>
            })}
            </p>
          </Modal.Body>
          <Modal.Footer>
          <button  className="btn btn-primary" onClick={()=>setShowModal(false)} >{commonLabels.OK}</button>

        </Modal.Footer>
        </Modal>
        </div> 
    )
};