/* eslint-disable */
import { SET_ADVERTISEMENT_DETAILS, SET_ADVERTISEMENT_LIST, SET_ADVERTISEMENT_SHOW_DETAILS, SET_APPLICATION_ELIGIBILITY_INFO, SET_APPLICATION_POST_PREF_LIST, SET_UPLOADED_DOCUMENT,SET_UPLOAD_DOCUMENT_INFO_ID, 
    SET_APPLICATION_IS_REQST_IN_PROGRESS,SET_PROCEED_TO_PAYMENT,SET_GOTO_MYACCOUNT,SET_APPLICATION_ID, SET_PROFILE_INFO_DISPLAY,  SET_CHECK_ELIGIBILITY_DISABLE,SET_RETOTALING_POST_SUBJECTS,SET_RETOTALING_REQUESTS,
    SET_RETOTALING_REQUEST_ID,SET_EASEBUZZ_ACCESS_KEY, SET_ALL_DEPARTMENTS_DATA,SET_ALLDEPARTMENTS_DATA} from '../../common/constants/ActionTypes';

const initialState = {
    advertisementList: [],
    advertisementDetailsInfo: {},
    examCenterOptionList: [],
    showAdvertisementDetails: false,
    advertisementId: null,
    eligibilityResultList: null,
    applicationFee: null,
    applicationPostPrefList: null,
    isReqstInProgress: false,
    profileInfo: null,
    proceedToPayment:false,
    applyedApplication:[],
    gotoMyAccountMsg:false,
    uploadedDocumentData: [],
    setCheckEligibilty: false,
    retotalingPostSubjectsList:[],
    retotalingRequests:[],
    retotalingRequestId:null,
    easebuzz_access_key:null,
    allDepartments:[],
    allPostsDepartments:[]
}

export default function profileReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ADVERTISEMENT_LIST:
            return {
                ...state,
                advertisementList: action.payload
            }
      
        case SET_ADVERTISEMENT_DETAILS:
            return {
                ...state,
                advertisementDetailsInfo: action.payload,
                examCenterOptionList: action.examCenterOptionList,
                eligibilityResultList: null,
                applicableAdditionalQuestionsList: null,
                applicationPostPrefList: null,
                profileInfo: null,
                applicationFee: null,
            }

        case SET_ADVERTISEMENT_SHOW_DETAILS:
            return {
                ...state,
                showAdvertisementDetails: action.payload,
                advertisementId: action.advertisementId,
            }

        case SET_APPLICATION_ELIGIBILITY_INFO:
            return {
                ...state,
                eligibilityResultList: (action.payload.eligibilityResultDtoList !==null && action.payload.eligibilityResultDtoList !=="null") ? action.payload.eligibilityResultDtoList : action.payload.multipleExamsEligibilityResults,
                applicationFee: action.payload.applicationFee,
                applicableAdditionalQuestionsList: action.payload.applicableAdditionalQuestionsDtoList,
            }

        case SET_APPLICATION_POST_PREF_LIST:
            return {
                ...state,
                applicationPostPrefList: action.payload
            }

        case SET_APPLICATION_IS_REQST_IN_PROGRESS:
            return {
                ...state,
                isReqstInProgress: action.payload
            }
        case SET_PROCEED_TO_PAYMENT:
                return {
                    ...state,
                    proceedToPayment: action.payload
                } 
        case SET_GOTO_MYACCOUNT:
                    return {
                        ...state,
                        gotoMyAccountMsg: action.payload
                    }       
        case SET_APPLICATION_ID:
                return {
                    ...state,
                    applyedApplication: action.payload
                    }
                
        case SET_PROFILE_INFO_DISPLAY:
            return {
                ...state,
                profileInfo: action.payload
            }
        case SET_UPLOADED_DOCUMENT:
            return {
                ...state,
                uploadedDocumentData: action.payload
            }
        case SET_UPLOAD_DOCUMENT_INFO_ID:
            return {
                ...state,
                uploadedDocumentData: state.uploadedDocumentData.map(doc=>action.payload.documentTypeId==doc.documentTypeId?
                    {...doc,documentInfoId:action.payload.documentInfoId,isUploaded:true}:doc)
            }
            
        case  SET_CHECK_ELIGIBILITY_DISABLE:
            return {
                ...state,
                setCheckEligibilty: action.payload
            }   
        case SET_RETOTALING_POST_SUBJECTS:
            return {
                ...state,
                retotalingPostSubjectsList:action.payload
            }  
        case SET_RETOTALING_REQUESTS:
            return {
                ...state,
                retotalingRequests:action.payload
            }    
        case SET_RETOTALING_REQUEST_ID:
            return {
                ...state,
                retotalingRequestId:action.payload
            }
        case SET_EASEBUZZ_ACCESS_KEY:
            return {
                ...state,
                easebuzz_access_key:action.payload
            }    
        case SET_ALL_DEPARTMENTS_DATA:
            return {
                ...state,
                allDepartments:action.payload,
            }
        case SET_ALLDEPARTMENTS_DATA:
            return {
                ...state,
                allPostsDepartments:action.payload,
            }
        default:
            return state;
    }
}


