/* eslint-disable */
import axios from 'axios';
import CRC32 from 'crc-32';
import { OAS_API_BASE_URL as baseUrlArr } from "../configuration";
import { decryptData, encryptData, forceFullyLogoutAPI} from '../common/actions/commonActions';
import { SECRET_KEY } from '../configuration';
import {
    HAS_PENDING_TRANSACTIONS, RECONCILE_NOW, BILLDESK, EASEBUZZ_CALLBACK, EASEBUZZ_EPAY, API_APPLICATION_RECEIPT, PAYMENTDETAILS, PAYNOW, SBIEPAY,
    QUIKWALLET_EPAY, QUIKWALLET_EPAY_RETOTALING, PAYNOW_RETOTALING, SBIEPAY_RETOTALING, EASEBUZZEPAY_RETOTALING, SBIEPAY_OBJECTION_REQUEST, QUIKWALLET_EPAY_OBJECTION_REQUEST,
    EASEBUZZEPAY_OBJECTION_REQUEST, BILLDESK_OBJECTION_REQUEST, CSC_OBJECTION_REQUEST, BILLDESK_RETOTALING, ADVERTISEMENT_PDF, PHOTO_URL, UPLOAD_DOCUMENT, UPLOADIMAGEREQ,
    API_CHALLAN_SLIP, DOWNLOADCORRESPONDENCEDOC, DOWNLOADMARKSHEET, DOWNLOAD_DOCUMENT, GETOBJECTIONREQUEST, API_CHALLAN_PAYMENT_RECEIPT, PROFILE_SUMMARY, PROFILE_INFO_DISPLAY, API_REPO_VERSION, FORCEFULLY_LOGOUT, ADDOBJECTIONREQUEST
} from '../common/constants/endpoints';
import registrationLabels from "../registration_login/labels/registration_en.json";
import { toastr } from 'react-redux-toastr';

let baseURL = "api.mpsconline.gov.in";
const currentDomain = window.location.hostname;
let logoutTriggered = false;
let invalidAccessFlg = false;
let objectByIdUrl = "/oas/api/v1/objection?id";

baseUrlArr && baseUrlArr.length > 0 && baseUrlArr.filter((e) => {
    if (currentDomain === e.key) {
        baseURL = e.value;
        axios.defaults.baseURL = e.baseUrl;
    }
});

export function generateCRC() {
    const dataWithSecret = SECRET_KEY
    const localCrcValue = CRC32.str(dataWithSecret).toString(16);
    return localCrcValue
}
const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: "json"
});
axiosInstance.interceptors.request.use(
    (config) => {
        const session = JSON.parse(sessionStorage.getItem("session"));
        const token = session?.jwtResponse?.token;
        const excludedEndpoints = [
            HAS_PENDING_TRANSACTIONS, RECONCILE_NOW, BILLDESK, EASEBUZZ_CALLBACK, EASEBUZZ_EPAY, API_APPLICATION_RECEIPT, PAYMENTDETAILS, PAYNOW, SBIEPAY, QUIKWALLET_EPAY, QUIKWALLET_EPAY_RETOTALING,
            PAYNOW_RETOTALING, SBIEPAY_RETOTALING, EASEBUZZEPAY_RETOTALING, SBIEPAY_OBJECTION_REQUEST, QUIKWALLET_EPAY_OBJECTION_REQUEST, EASEBUZZEPAY_OBJECTION_REQUEST, BILLDESK_OBJECTION_REQUEST,
            CSC_OBJECTION_REQUEST, BILLDESK_RETOTALING, ADVERTISEMENT_PDF, PHOTO_URL, UPLOAD_DOCUMENT, UPLOADIMAGEREQ, API_CHALLAN_SLIP, DOWNLOADCORRESPONDENCEDOC, DOWNLOADMARKSHEET, DOWNLOAD_DOCUMENT,
            GETOBJECTIONREQUEST, API_CHALLAN_PAYMENT_RECEIPT, PROFILE_SUMMARY, PROFILE_INFO_DISPLAY, API_REPO_VERSION, objectByIdUrl
        ];
        const isExcludedEndpoint = excludedEndpoints.some(endpoint => config.url.includes(endpoint));
        if (token && !FORCEFULLY_LOGOUT) {
            config.headers.Authorization = `Bearer ${token}|#|#${generateCRC()}`;
        }
        if (config.headers['Content-Type'] === 'multipart/form-data') {
            return config;
        }

        if (config.method !== 'get' && config.data && !config.data.encryptedReqBody && !isExcludedEndpoint) {
            config.data = { encryptedReqBody: encryptData(JSON.stringify(config.data)) };
        }
        
        if (config.method !== 'get') {
            let dataWithSecret = "";
            let excludedUrl = [EASEBUZZEPAY_OBJECTION_REQUEST, SBIEPAY_OBJECTION_REQUEST, BILLDESK_OBJECTION_REQUEST, QUIKWALLET_EPAY_OBJECTION_REQUEST, CSC_OBJECTION_REQUEST, EASEBUZZEPAY_RETOTALING, BILLDESK_RETOTALING, SBIEPAY_RETOTALING, PAYNOW_RETOTALING, QUIKWALLET_EPAY_RETOTALING]
            const isExcludedUrls = excludedUrl.some(endpoint => config.url.includes(endpoint));

            if(config.method === "post" && isExcludedUrls){
                dataWithSecret = encryptData(JSON.stringify(config.data)) + SECRET_KEY;
            }else{
                dataWithSecret = Object.keys(config.data).length === 0 ? encryptData("{}") + SECRET_KEY : config.data.encryptedReqBody + SECRET_KEY;
            }
            const crcValue = CRC32.str(dataWithSecret);
            const unsignedCrcValue = (crcValue >>> 0).toString(16);
            // config.headers['Access-Control-Allow-OAS'] = unsignedCrcValue;
            if(token){
                config.headers.Authorization = `Bearer ${token}|#|#${unsignedCrcValue}`;
            }else{
                config.headers.Authorization = `|#|#${unsignedCrcValue}`
            }
        } else {
                // config.headers['Access-Control-Allow-OAS'] = generateCRC();
            if(token){
                config.headers.Authorization = `Bearer ${token}|#|#${generateCRC()}`;
            }else{
                config.headers.Authorization = `|#|#${generateCRC()}`;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor for decryption od data 
axiosInstance.interceptors.response.use(
    (response) => {
        const excludedEndpoints = [
            HAS_PENDING_TRANSACTIONS, RECONCILE_NOW, BILLDESK, EASEBUZZ_CALLBACK, EASEBUZZ_EPAY, API_APPLICATION_RECEIPT, PAYMENTDETAILS, PAYNOW, SBIEPAY,
            QUIKWALLET_EPAY, QUIKWALLET_EPAY_RETOTALING, PAYNOW_RETOTALING, SBIEPAY_RETOTALING, EASEBUZZEPAY_RETOTALING, SBIEPAY_OBJECTION_REQUEST,
            QUIKWALLET_EPAY_OBJECTION_REQUEST, EASEBUZZEPAY_OBJECTION_REQUEST, BILLDESK_OBJECTION_REQUEST, CSC_OBJECTION_REQUEST, BILLDESK_RETOTALING,
            ADVERTISEMENT_PDF, PHOTO_URL, UPLOAD_DOCUMENT, UPLOADIMAGEREQ, API_CHALLAN_SLIP, DOWNLOADCORRESPONDENCEDOC, DOWNLOADMARKSHEET, DOWNLOAD_DOCUMENT,
            GETOBJECTIONREQUEST, API_CHALLAN_PAYMENT_RECEIPT, PROFILE_SUMMARY, PROFILE_INFO_DISPLAY, API_REPO_VERSION, objectByIdUrl
        ];
        const isExcludedEndpoint = excludedEndpoints.some(endpoint => response.config.url.includes(endpoint));
        if (response.data && !isExcludedEndpoint && !((response.config.method === "patch" || response.config.method === "post") && response.config.url === "/oas/api/v1/objection")) {
            const jsonString = response.data;
            const dataWithSecret = jsonString + SECRET_KEY;
            const crcValue = CRC32.str(dataWithSecret);
            const frontendCrcValue = (crcValue >>> 0).toString(16);
            // let backendCrcValue = response.headers["access-control-allow-oas"];
            let AuthorizationVal = response.headers["authorization"];
            const backendCrcValue = AuthorizationVal.split("|#|#")[1];
            if(frontendCrcValue !== backendCrcValue){
                try {
                    toastr.error(registrationLabels.INVALID_ACCESS);
                    forceFullyLogoutAPI('', true);
                    //  console.log("FrontendCrcValue: ", frontendCrcValue);
                    // console.log("BackendCrcValue: ", backendCrcValue);
                    // console.log("ConfigUrl",response.config.url);
                    // console.log("Response Data",response.data)
                    // let strData = JSON.stringify(response.data)
                    // console.log("String Data",strData)
                } catch (error) {
                    console.error(registrationLabels.ERR_RES_MES, error.response);
                }
            }
        }else if(response.data && isExcludedEndpoint){
            let jsonString = '';
            if (typeof response.data === 'string') {
                jsonString = encryptData(response.data)
            }else{
                jsonString = encryptData(JSON.stringify(response.data))
            }
            // const dataWithSecret = jsonString + SECRET_KEY;
            // const crcValue = CRC32.str(dataWithSecret);
            // const frontendCrcValue = (crcValue >>> 0).toString(16);
            // let backendCrcValue = response.headers["access-control-allow-oas"];
            // if(frontendCrcValue === backendCrcValue){
            //     console.log("values match")
            // }else{
            //     console.log("FrontendCrcValue: ", frontendCrcValue);
            //     console.log("BackendCrcValue: ", backendCrcValue);
            //     console.log("ConfigUrl",response.config.url);
            //     console.log("Response Data",response.data)
            //     let strData = JSON.stringify(response.data)
            //     console.log("String Data",strData)
            // }
        }
        if (response.data && !isExcludedEndpoint) {
            try {
                response.data = JSON.parse(decryptData(response.data));
            } catch (error) {
                response.data = decryptData(response.data);
            }
        }
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 500 && !logoutTriggered && (error.response.data && error.response.data.message && error.response.data.message === registrationLabels.RELOGIN_MES)) {
                logoutTriggered = true;
                try {
                    forceFullyLogoutAPI('', true);
                } catch (error) {
                    console.error(registrationLabels.ERR_RES_MES, error.response);
                }
            }

            if (error.response.status === 400 && !invalidAccessFlg && (error.response.data && error.response.data.message && error.response.data.message === registrationLabels.CRC_VALUE_DOES_NOT_MATCH)) {
                invalidAccessFlg = true;
                try {
                    forceFullyLogoutAPI('', true);
                } catch (error) {
                    console.error(registrationLabels.ERR_RES_MES, error.response);
                }
            }
        }
        return Promise.reject(error);
    }
);
export const axiosInstanceWithoutEncrDecr = axios.create({
    headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-OAS': generateCRC()
    },
    responseType: "json"
});
export default axiosInstance;