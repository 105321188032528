/* eslint-disable */
import { toastr } from 'react-redux-toastr';
import { hideLoader, showLoader } from '../../common/actions/commonActions';
import {
    SET_ADDRESS_INFO, SET_DISTRICTS, SET_DISTRICTS_CORRESPONDENCE, SET_ISSUING_DISTRICTS, SET_PROFILE_CREATION_LOOKUPS,
    SET_STATES, SET_TALUKAS, SET_TALUKAS_CORRESPONDENCE, SET_VILLAGES, SET_VILLAGES_CORRESPONDENCE, SET_OTHER_INFORMATION, SET_PERSONAL_INFO,
    SET_EXPERIENCE_INFO, SET_QUALIFICATION_TYPES, SET_QUALIFICATION_NAMES, SET_SUBJECTS, SET_UNIVERSITIES, SET_UPLOADED_PHOTO, SET_QUALIFICATION_INFORMATION, ADD_QUALIFICATION, ADD_EXPERIENCEINFO,
    EDIT_EXPERIENCEINFO,
    SET_DISPLAYABLE_QUALIFICATION_LIST, SET_IMAGE_SRC, SET_CROPE_IMAGE_SRC, SET_SIGNATURE_SRC,
    SET_CROP_SIGNATURE_SRC, SET_IMAGE_SIGNATURE_FLAG, EDIT_QUALIFICATION, SET_SELECTED_QUALIFICATION_TYPE,
    EDIT_QUALIFICATION_DONE_RESET,
    SET_PROFILE_STATUS,
    SET_A_SUBMITTABLE_FLG,
    SET_EXPERIENCEINFO_EDIT_FLAG, SET_DISABLE_ALL_TAB_SAVE, SET_CURRENT_EDIT_QUALIFICATION_INDEX, IS_QUALIFICATION_TYPE_CHANGED_BY_DROPDOWN, SET_SPORT_DETAILS_LIST, EDIT_SPORT_DETAILS_LIST, CLEAR_SPORT_DTO, SET_UPDATE_PROFILE_DONE,
    SET_EXPERIENCE_TABLE_LIST,SET_ADDITIONAL_RES_ENV_DATA,SET_ALL_DISABILITIES_DATA, SET_PUBLICATION_TYPE_DATA, GET_NAME_OF_INDEXING_BODIES, GET_TYPE_OF_ARTICLES, GET_ALL_JOB_DESIGNATION_LIST, SET_UDID_FLAG, SET_UDID_RESPONSE_MESSAGE
} from '../../common/constants/ActionTypes';
import {
    DISTRICTS, EXPERIENCE_INFORMATION, LOOKUP_OPTION, STATES, TALUKAS, VILLAGES,
    PROFILE_CREATION, QUALIFICATION_TYPES, ADDRESS_INFORMATION, OTHER_INFORMATION, QUALIFICATION_NAME, SUBJECTS, UNIVERSITIES, PHOTO_URL, QUALIFICATION_INFORMATION, GET_DISPLAYABLE_QUALIFICATION_LIST, PROFILE,GET_ADDITIONAL_RES_ENV
    ,LOOKUP_OPTIONS, GET_PUBLICATION_TYPE_DATA, VALIDATE_UDID, VERIFY_UDID
} from '../../common/constants/endpoints';
import API from '../../helpers/API';
import dayjs from 'dayjs';
import { dateDifferece } from '../../helpers/Datetime';
import { mandatoryEducation } from '../constants/QualificationConstants';
import qualificationinformation from '../labels/qualification_information_en.json'
import { experienceInfoReqDTO } from "../constants/experienceInfoReqDTO";
import commonLabels from "../../my_account/labels/common_en.json";
import '../../styles/profile.css'

const processAddressInfoResponse = (response, dispatch) => {
    if (response && response.data) {
        let addressInformationResponse = {
            permenantAddressState: {
                permenantHouseNo: response.data.permenantHouseNo,
                permenantBuildingName: response.data.permenantBuildingName,
                permenantRoadOrPo: response.data.permenantRoadOrPo,
                permenantArea: response.data.permenantArea,
                permenantLandmark: response.data.permenantLandmark,
                permenantState: response.data.permenantStateLookupId,
                permenantDistrict: response.data.permenantDistrictLookupId,
                permenantTaluka: response.data.permenantTalukaLookupId,
                permenantVillageCity: response.data.permenantvillageCityLookupId,
                permenantDistrict1: response.data.permenantDistrict,
                permenantTaluka1: response.data.permenantTaluka,
                permenantVillageCity1: response.data.permenantvillageCity,
                permenantPin: response.data.permenantPin
            },
            correspondenceAddressState: {
                correspondenceHouseNo: response.data.correspondenceHouseNo,
                correspondenceBuildingName: response.data.correspondenceBuildingName,
                correspondenceRoadOrPo: response.data.correspondenceRoadOrPo,
                correspondenceArea: response.data.correspondenceArea,
                correspondenceLandmark: response.data.correspondenceLandmark,
                correspondenceState: response.data.correspondenceStateLookupId,
                correspondenceDistrict: response.data.correspondenceDistrictLookupId,
                correspondenceTaluka: response.data.correspondenceTalukaLookupId,
                correspondenceVillageCity: response.data.correspondenceVillageCityLookupId,
                correspondenceDistrict1: response.data.correspondenceDistrict,
                correspondenceTaluka1: response.data.correspondenceTaluka,
                correspondenceVillageCity1: response.data.correspondenceVillage,
                correspondencePin: response.data.correspondencePin
            },
            isSameAsAbove: response.data.flgSameasabove
        };
        dispatch({
            payload: addressInformationResponse,
            type: SET_ADDRESS_INFO
        });
        dispatch({
            payload: { "addressInfo": response.data.flgSubmittable },
            type: SET_A_SUBMITTABLE_FLG
        });
    }
};

const processQualificationInfoResponse = (response, dispatch) => {
    if (response && response.data) {
        let qualificationInformationResponse = response.data;
        dispatch({
            payload: qualificationInformationResponse,
            type: SET_QUALIFICATION_INFORMATION
        });
        let updatedFlgSubmittable = (response.data.length > 0) ? response.data.every(elem => elem.flgSubmittable) : false;
        dispatch({
            payload: { "qualificationInfo": updatedFlgSubmittable },
            type: SET_A_SUBMITTABLE_FLG
        });
    }
};

const processExperienceInfoResponse = (response, dispatch) => {
    if (response && response.data) {
        let experienceResponseList = response.data;
        let experienceInformationResponse = [];
        for (let i = 0; i < experienceResponseList.length; i++) {
            if (!experienceResponseList[i].flgExperience) {
                continue;
            }
            let employmentFromDate = experienceResponseList[i].employmentFrom ? dayjs(experienceResponseList[i].employmentFrom).toDate() : new Date();
            let employmentToDate = experienceResponseList[i].employmentTo ? dayjs(experienceResponseList[i].employmentTo).toDate() : "";
            let appointmentLetterDate = experienceResponseList[i].appointmentLetterDate ? dayjs(experienceResponseList[i].appointmentLetterDate).toDate() : "";
            experienceInformationResponse.push({
                typeEmploymentLookupId: experienceResponseList[i].typeEmploymentLookupId,
                flgMpscSelection: experienceResponseList[i].flgMpscSelection,
                postNameLookupId: experienceResponseList[i].postNameLookupId,
                postNameOther: experienceResponseList[i].postNameOther,
                officeName: experienceResponseList[i].officeName,
                flgOfficeGovOwned: experienceResponseList[i].flgOfficeGovOwned,
                designation: experienceResponseList[i].designation,
                designationId: experienceResponseList[i].designationId,
                jobNatureLookupId: experienceResponseList[i].jobNatureLookupId,
                flgGazettedPost: experienceResponseList[i].flgGazettedPost,
                typeGroupLookupId: experienceResponseList[i].typeGroupLookupId,
                apointmentNatureLookupId: experienceResponseList[i].apointmentNatureLookupId,
                fullTimeLookupId: experienceResponseList[i].fullTimeLookupId,
                appointmentLetterNo: experienceResponseList[i].appointmentLetterNo,
                appointmentLetterDate: appointmentLetterDate,
                payScale: experienceResponseList[i].payScale,
                gradePay: experienceResponseList[i].gradePay,
                basicPay: experienceResponseList[i].basicPay,
                monthlyGrossSalary: experienceResponseList[i].monthlyGrossSalary,
                employmentFrom: employmentFromDate,
                employmentTo: employmentToDate,
                expYears: experienceResponseList[i].expYears,
                expMonths: experienceResponseList[i].expMonths,
                expDays: experienceResponseList[i].expDays,
                remarks: experienceResponseList[i].remarks,
                id: experienceResponseList[i].id,
                flgDeleted: experienceResponseList[i].flgDeleted,
                flgSubmittable: experienceResponseList[i].flgSubmittable,
                flgExperience: experienceResponseList[i].flgExperience
            });
        }
        dispatch({
            payload: experienceInformationResponse,
            type: SET_EXPERIENCE_INFO
        })
        let updatedFlgSubmittable = (response.data.length > 0) ? response.data.every(elem => elem.flgSubmittable) : false;
        dispatch({
            payload: { "experienceInfo": updatedFlgSubmittable },
            type: SET_A_SUBMITTABLE_FLG
        });
    };
};

export const addressAction = (newaddressDetails) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token
            let profileId = session.profileId;
            let userId = session.userId;
            let request = {
                "permenantHouseNo": newaddressDetails.permenantHouseNo,
                "permenantBuildingName": newaddressDetails.permenantBuildingName,
                "permenantRoadOrPo": newaddressDetails.permenantRoadOrPo,
                "permenantArea": newaddressDetails.permenantArea,
                "permenantLandmark": newaddressDetails.permenantLandmark,
                "permenantStateLookupId": newaddressDetails.permenantState,
                "permenantDistrictLookupId": newaddressDetails.permenantDistrict,
                "permenantDistrict": newaddressDetails.permenantDistrict1,
                "permenantTalukaLookupId": newaddressDetails.permenantTaluka,
                "permenantTaluka": newaddressDetails.permenantTaluka1,
                "permenantvillageCityLookupId": newaddressDetails.permenantVillageCity,
                "permenantvillageCity": newaddressDetails.permenantVillageCity1,
                "permenantPin": newaddressDetails.permenantPin,
                "correspondenceHouseNo": newaddressDetails.correspondenceHouseNo,
                "correspondenceBuildingName": newaddressDetails.correspondenceBuildingName,
                "correspondenceRoadOrPo": newaddressDetails.correspondenceRoadOrPo,
                "correspondenceArea": newaddressDetails.correspondenceArea,
                "correspondenceLandmark": newaddressDetails.correspondenceLandmark,
                "correspondenceStateLookupId": newaddressDetails.correspondenceState,
                "correspondenceDistrictLookupId": newaddressDetails.correspondenceDistrict,
                "correspondenceTalukaLookupId": newaddressDetails.correspondenceTaluka,
                "correspondenceVillageCityLookupId": newaddressDetails.correspondenceVillageCity,
                "correspondenceDistrict": newaddressDetails.correspondenceDistrict1,
                "correspondenceTaluka": newaddressDetails.correspondenceTaluka1,
                "correspondenceVillage": newaddressDetails.correspondenceVillageCity1,
                "correspondencePin": newaddressDetails.correspondencePin,
                "profileId": profileId,
                "userId": userId,
                "flgSubmittable": 1,
                "flgSameasabove": newaddressDetails.isSameAsAbove
            }
            if (parseInt(request.permenantStateLookupId) === 27) {
                request.permenantDistrict = null
                request.permenantTaluka = null
                request.permenantvillageCity = null
            } else {
                request.permenantDistrictLookupId = null
                request.permenantTalukaLookupId = null
                request.permenantvillageCityLookupId = null
            }
            if (parseInt(request.correspondenceStateLookupId) === 27) {
                request.correspondenceDistrict = null
                request.correspondenceTaluka = null
                request.correspondenceVillage = null
            } else {
                request.correspondenceDistrictLookupId = null
                request.correspondenceTalukaLookupId = null
                request.correspondenceVillageCityLookupId = null
            }
            let response;
            let isFirstPost = false;
            if (profileId) {
                let getResponse = await API.get(ADDRESS_INFORMATION + `/${userId}/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
                if (getResponse && getResponse.data) {
                    response = await API.patch(ADDRESS_INFORMATION + `/${userId}/${profileId}`, request, { headers: { 'Authorization': `Bearer ${token}` } });
                } else {
                    isFirstPost = true;
                }
                if(getResponse!=null)
                {
                    dispatch(hideLoader());
                }
            } else {
                dispatch(hideLoader());
                isFirstPost = true
            }
            if (isFirstPost) {
                response = await API.post(ADDRESS_INFORMATION, request, { headers: { 'Authorization': `Bearer ${token}` } });
                if (response && response.data) {
                    const session = JSON.parse(sessionStorage.getItem("session"));
                    session.profileId = response.data.profileId;
                    sessionStorage.setItem("session", JSON.stringify(session));
                }
                if(response!=null)
                {
                    dispatch(hideLoader());
                }
            }
            else
            {
                dispatch(hideLoader());
            }
            if (response) {
                toastr.success(commonLabels.ADD_SAVE_NOTE);
                window.scrollTo(0,0);
                processAddressInfoResponse(response, dispatch);
            }
        } catch (error) {
            dispatch(hideLoader());
            if (error.response && error.response.data) {
                toastr.error(error.response.data.message);
            } else {
                toastr.error(error.message);
            }
        }
    }
};

export const addExperienceList = (experienceInfoToBeAdded, experiencesList) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ADD_EXPERIENCEINFO,
                payload: [...experiencesList, experienceInfoToBeAdded]
            })
            toastr.info(commonLabels.REC_ADD_NOTE)
        } catch (error) {
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_ADDING_EXP_DETAILS, errorMessage);
        }
    }
};

export const editExperienceInfo = (experienceToBeEdited, existingexperiencesList) => {
    return async (dispatch) => {
        try {
            let editIndex = existingexperiencesList.findIndex((ele) => {
                return (ele.id === experienceToBeEdited.id)
            });
            if (editIndex !== -1) {
                existingexperiencesList[editIndex] = experienceToBeEdited
                toastr.info(commonLabels.REC_UPDATED_NOTE);
                dispatch({
                    type: SET_EXPERIENCEINFO_EDIT_FLAG,
                    payload: false
                })
                dispatch({
                    type: ADD_EXPERIENCEINFO,
                    payload: existingexperiencesList
                })
            }
        } catch (error) {
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_EDITING_QUA_DETAILS, errorMessage);
        }
    }
};

export const saveExperienceList = (experiencesList, flgExperience) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            if (flgExperience === "Yes" && experiencesList.length === 0) {
                dispatch(hideLoader());
                toastr.info(commonLabels.NO_EXP_ADDED_NOTE);
                return
            }
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            let profileId = session.profileId;
            let userId = session.userId;
            let isFirstPost = false;
            let request = []
            let response;
            let getResponse;
            if (flgExperience === "No") {
                if (experiencesList.length > 0) {
                    toastr.info(commonLabels.DELETE_SAVE_EXP_NOTE);
                    return;
                } else {
                    request.push({
                        flgDeleted: 0,
                        profileId: profileId,
                        ...experienceInfoReqDTO,
                        userId: userId,
                        flgSubmittable: 1,
                        flgExperience: false
                    })
                }
            } else {
                for (let i = 0; i < experiencesList.length; i++) {
                    request.push({
                        flgDeleted: 0,
                        profileId: profileId,
                        ...experiencesList[i],
                        userId: userId,
                        flgSubmittable: 1,
                        flgExperience: true
                    })
                }
                dispatch({
                    payload: { "experienceInfo": true },
                    type: SET_A_SUBMITTABLE_FLG
                });
            }
            if (profileId) {
                getResponse = await API.get(EXPERIENCE_INFORMATION + `/${userId}/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
                if(getResponse!=null)
                {
                    dispatch(hideLoader());
                }
                if (flgExperience === "No") {

                    if (getResponse.data && getResponse.data[0]) {
                        request[0].id = getResponse.data[0].id;
                        isFirstPost = false;
                        dispatch({
                            payload: { "experienceInfo": true },
                            type: SET_A_SUBMITTABLE_FLG
                        });
                    } else {
                        request[0].id = "";
                        isFirstPost = true;
                    }

                } else {
                    if (getResponse.data && getResponse.data.length > 0) {
                        response = await API.patch(EXPERIENCE_INFORMATION + `/${userId}/${profileId}`, request, { headers: { 'Authorization': `Bearer ${token}` } });
                    } else {
                        isFirstPost = true;
                    }
                }
            } else {
                    dispatch(hideLoader());
                isFirstPost = true
            }
            if (isFirstPost) {
                response = await API.post(EXPERIENCE_INFORMATION, request, { headers: { 'Authorization': `Bearer ${token}` } });
                if (response && response.data) {
                    const session = JSON.parse(sessionStorage.getItem("session"));
                    session.profileId = response.data[0].profileId;
                    sessionStorage.setItem("session", JSON.stringify(session));
                }
                if(response!=null)
                {
                    dispatch(hideLoader());
                }
            }
            else
            {
                dispatch(hideLoader());
            }
            if (response) {
                toastr.success(commonLabels.EXP_SAVED_NOTE);
                window.scrollTo(0,0);
                dispatch(getExperienceInformation());
                dispatch({
                    payload: (response && response.data) ? response.data : [],
                    type: SET_EXPERIENCE_INFO
                });
                let updatedFlgSubmittable = (response.data.length > 0) ? response.data[0].flgSubmittable : false;
                dispatch({
                    payload: { "experienceInfo": updatedFlgSubmittable },
                    type: SET_A_SUBMITTABLE_FLG
                });
            }
        } catch (error) {
                    dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data)
                ? error.response.data.message
                : error.message;
            toastr.error(commonLabels.EXP_INFO_INSERTION_ERR, errorMessage);
        }
    }
};

export const getExperienceInformation = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let profileId = session ? session.profileId : "";
        if (!profileId) {
            dispatch(hideLoader());
            return
        }
        let token = session.jwtResponse && session.jwtResponse.token
        let userId = session.userId;
        let response = await API.get(EXPERIENCE_INFORMATION + `/${userId}/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
        processExperienceInfoResponse(response, dispatch);
        if(response!=null)
                {
                    dispatch(hideLoader());
                }
    }
};

export const getDetailsForEditExperience = (experienceToBeEdited) => {
    return async (dispatch) => {
        dispatch({
            type: EDIT_EXPERIENCEINFO,
            payload: experienceToBeEdited
        })
        dispatch({
            type: SET_EXPERIENCEINFO_EDIT_FLAG,
            payload: true
        })
    }
};

export const clearEditExperienceInfo = (clearEditExperienceInfo) => {
    return async (dispatch) => {
        dispatch({
            type: EDIT_EXPERIENCEINFO,
            payload: clearEditExperienceInfo
        })
        dispatch({
            type: SET_EXPERIENCEINFO_EDIT_FLAG,
            payload: false
        })
    }
};

export const setProfileCreationDropdown = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response = await API.get(LOOKUP_OPTION, {
            params: { module: "profile_creation" }
        });
        let countryid = 88;
        let states_response = await API.get(STATES + `/${countryid}`);
        if(response!=null || states_response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: (response && response.data) ? response.data : [],
            type: SET_PROFILE_CREATION_LOOKUPS
        });
        dispatch({
            payload: (states_response && states_response.data) ? states_response.data : [],
            type: SET_STATES
        });
    }
};

export const getAllDisabilities = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response = await API.get(LOOKUP_OPTIONS, {
            params: {
                 module: "profile_creation",
                 type: "disability_sub_category"
                }
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: (response && response.data) ? response.data : [],
            type: SET_ALL_DISABILITIES_DATA
        });
    }
};

export const setDistrictDropdown = (statecode) => {
    return async (dispatch) => {
        let payload = [];
        if (statecode !== "") {
            let district_response = await API.get(DISTRICTS + `/${statecode}`);
            payload = (district_response && district_response.data) ? district_response.data : [];
        }
        dispatch({
            payload: payload,
            type: SET_DISTRICTS
        });
    }
};

export const setDistrictDropdownCorrespondenceAddress = (statecode) => {
    return async (dispatch) => {
        let payload = [];
        if (statecode !== "") {
            let district_response = await API.get(DISTRICTS + `/${statecode}`);
            payload = (district_response && district_response.data) ? district_response.data : [];
        }
        dispatch({
            payload: payload,
            type: SET_DISTRICTS_CORRESPONDENCE
        });
    }
};

export const setTalukaDropdown = (districtcode) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let payload = [];
        if (districtcode !== "" && districtcode != null) {
            let taluka_response = await API.get(TALUKAS + `/${districtcode}`);
            payload = (taluka_response && taluka_response.data) ? taluka_response.data : [];
            if(taluka_response!=null)
                {
                    dispatch(hideLoader());
                }
        }
        else
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: payload,
            type: SET_TALUKAS
        });
    }
};

export const setTalukaDropdownCorrespondenceAddress = (districtcode) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let payload = [];
        if (districtcode !== "" && districtcode != null) {
            let taluka_response = await API.get(TALUKAS + `/${districtcode}`);
            payload = (taluka_response && taluka_response.data) ? taluka_response.data : [];
            if(taluka_response!=null)
                {
                    dispatch(hideLoader());
                }
        }
        else
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: payload,
            type: SET_TALUKAS_CORRESPONDENCE
        });
    }
};

export const setVillageDropdown = (subdistrictcode) => {
    return async (dispatch) => {
    dispatch(showLoader());
        let payload = [];
        if (subdistrictcode !== "" && subdistrictcode != null) {
            let village_response = await API.get(VILLAGES + `/${subdistrictcode}`);
            payload = (village_response && village_response.data) ? village_response.data : [];
            if(village_response!=null)
                {
                    dispatch(hideLoader());
                }
        }
        else
            {
                dispatch(hideLoader());
            }
        dispatch({
            payload: payload,
            type: SET_VILLAGES
        });
    }
};

export const setVillageDropdownCorrespondenceAddress = (subdistrictcode) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let payload = [];
        if (subdistrictcode !== "" && subdistrictcode != null) {
            let village_response = await API.get(VILLAGES + `/${subdistrictcode}`);
            payload = (village_response && village_response.data) ? village_response.data : [];
            if(village_response!=null)
            {
                dispatch(hideLoader());
            }
        }
        else
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: payload,
            type: SET_VILLAGES_CORRESPONDENCE
        });
    }
};

export const setIssuingDistrictDropdown = () => {
    return async (dispatch) => {
        let district_response = await API.get(DISTRICTS + `/27`);
        dispatch({
            payload: (district_response && district_response.data) ? district_response.data : [],
            type: SET_ISSUING_DISTRICTS
        });
    }
};

export const getPersonalInfoData = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            if (session.profileId) {
                let token = session.jwtResponse && session.jwtResponse.token
                let personal_info = await API.get(PROFILE_CREATION + `/${session.profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
                let responseOfPersonalInfo = personal_info.data;
                if(personal_info!=null)
                {
                    dispatch(hideLoader());
                }
                dispatch({
                    payload: responseOfPersonalInfo,
                    type: SET_PERSONAL_INFO
                });
                dispatch({
                    payload: { "personalInfo": personal_info.data.flgSubmittable },
                    type: SET_A_SUBMITTABLE_FLG
                });
            }
            else
            {
                dispatch(hideLoader());
            }

        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(commonLabels.ERROR_WHILE_FETCHING_PERSONAL_INFO, errorMessage);
        }
    }
};

export const savePersonalInfoData = (data) => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let userId = session ? session.userId : null;
        let isFirstPost = false;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token
            let profileId = session.profileId;
            let reqBody = {
                "userId": userId,
                "flgAadhaarCard": data.isAdharCardPresent ? (data.isAdharCardPresent === 'Yes' ? true : false) : null,
                "aadhaarNo": data.aadhaarNo ? data.aadhaarNo.trim() : null,
                "aadhaarEnrolmentNo": data.aadhaarEnrolmentNo ? data.aadhaarEnrolmentNo : null,
                "aadhaarNameInEnglish": data.aadhaarNameInEnglish ? data.aadhaarNameInEnglish.trim() : null,
                "firstName": data.firstName ? data.firstName.trim() : null,
                "middleName": data.middleName ? data.middleName.trim() : null,
                "surname": data.surName ? data.surName.trim() : null,
                "englishFullName": data.fullNameInEnglish ? data.fullNameInEnglish.trim() : null,
                "devnagariFullName": data.devnagari ? data.devnagari.trim() : null,
                "motherName": data.mothersName ? data.mothersName.trim() : null,
                "dob": data.dateOfBirth ? data.dateOfBirth : null,
                "age": data.age ? data.age : null,
                "genderLookupId": data.gender ? data.gender : null,
                "flgTransgenderFemale": data.flgTransgenderFemale === 'Yes' ? true : false,
                "mobileNumber": data.mobileNumber ? data.mobileNumber.trim() : null,
                "altMobileNumber": data.alternateMobileNumber ? data.alternateMobileNumber : null,
                "emailId": data.emailID ? data.emailID.trim() : null,
                "altEmailId": data.altEmailId ? data.altEmailId : null,
                "maritalStatusLookupId": data.maritalStatus ? data.maritalStatus : null,
                "flgCandidateLiable": data.flgCandidateLiable ? (data.flgCandidateLiable === 'Yes' ? true : false) : null,
                "nationalityLookupId": data.Nationality ? data.Nationality : null,
                "flgDomicile": data.maharashtraDomicileCertificate ? (data.maharashtraDomicileCertificate === 'Yes' ? true : false) : null,
                "flgDomicileCertificate": data.domicileCertificateAvailable ? (data.domicileCertificateAvailable === 'Yes' ? true : false) : null,
                "domicileCertificateNo": data.domicileCertificateNumber ? data.domicileCertificateNumber.trim() : null,
                "domicileIssuingDistrict": data.domicileIssuingDistrict ? data.domicileIssuingDistrict : null,
                "domicileIssuingDate": data.domicileIssuingDate ? data.domicileIssuingDate : null,
                "motherTongueLookupId": data.motherTongue ? data.motherTongue : null,
                "flgMarathiRead": data.canYouRead === 'Yes' ? true : false,
                "flgMarathiWrite": data.canYouWrite === 'Yes' ? true : false,
                "flgMarathiSpeak": data.canYouSpeak === 'Yes' ? true : false,
                "flgDivyang": data.areYouDivyangjan ? (data.areYouDivyangjan === 'Yes' ? true : false) : null,
                "typeOfDisabilityLookupId": data.typeOfDisabilityLookupId ? data.typeOfDisabilityLookupId : null,
                "disabilitySubCategoriesLookupIds": data.disabilitySubCategoriesLookupIds ? data.disabilitySubCategoriesLookupIds : null,
                "typeDisabilityCertificate": data.typeDisabilityCertificate ? data.typeDisabilityCertificate : null,
                "flgDisabilityScribeReq": true,
                "flgDisabilityCompTime": true,
                "scribeArrangedBy": "MPSC",
                "sadmCertificateDate": data.sadmCertificateDate ? data.sadmCertificateDate : null,
                "sadmCertificateNumber": data.sadmCertificateNumber ? data.sadmCertificateNumber.trim() : null,
                "udid": data.udid ? data.udid : '',
                "udidEnrolmentNo": data.udidEnrolmentNo ? data.udidEnrolmentNo : '',
                "divyangPercentage": data.divyangPercentage ? data.divyangPercentage.trim() : null,
                "height": data.HeightInCMS ? data.HeightInCMS.trim() : null,
                "weight": data.Weight ? data.Weight.trim() : null,
                "chestDeflated": data.chestDeflated ? data.chestDeflated.trim() : null,
                "chestInflated": data.chestInflated ? data.chestInflated.trim() : null,
                "categoryLookupId": data.castType ? data.castType : null,
                "castCertificateNo": data.castCertificateNumber ? data.castCertificateNumber.trim() : null,
                "ewsCertificateNumber": data.ewsCertificateNumber ? data.ewsCertificateNumber.trim() : null,
                "ccIssuingDistrictLookupId": data.castCertificateDistrict ? data.castCertificateDistrict : null,
                "castCertiIssuingDate": data.castCertiIssuingDate ? data.castCertiIssuingDate : null,
                "flgNcl": data.doYouBelongToNonCreamyLayer ? (data.doYouBelongToNonCreamyLayer === 'Yes' ? true : false) : null,
                "nclCertificateNo": data.nclCertificateNo ? data.nclCertificateNo.trim() : null,
                "latestNclIssueDate": data.latestNclIssueDate ? data.latestNclIssueDate : null,
                "flgCastValidityCert": data.doYouHaveCastValidityCertificate ? (data.doYouHaveCastValidityCertificate === 'Yes' ? true : false) : "",
                "castValidityCertNo": data.castValidity ? data.castValidity.trim() : null,
                "castValidityCertIssueDate": data.castValidityCertIssueDate ? data.castValidityCertIssueDate : null,
                "flgOrphanReservation": data.DoYouWantTotakeAvantageOfOrphan ? (data.DoYouWantTotakeAvantageOfOrphan === 'Yes' ? true : false) : null,
                "flgOrphanReservationCerti": data.OrphanCertificateObtainedOrNot ? (data.OrphanCertificateObtainedOrNot === 'Yes' ? true : false) : null,
                "orphanReservationCertiNo": data.OrphanCertificateNumber ? data.OrphanCertificateNumber.trim() : null,
                "orphanType": data.orphanType ? data.orphanType : null,
                "orphanCertiIssueDate": data.orphanCertiIssueDate ? data.orphanCertiIssueDate : null,
                "orphanCertiAckReceipt": data.AcknowledgementReceiptNumber ? data.AcknowledgementReceiptNumber : null,
                "flgSubmittable": true,
                "flgMetritoriousSportsman": data.Meritorious_Sports_Person ? (data.Meritorious_Sports_Person === 'Yes' ? true : false) : null,
                "meritoriousSportsmanDtoList": data.meritoriousSportsmanDtoList ? data.meritoriousSportsmanDtoList : [],
                "flgExArmedReg": data.flgExArmedReg ? (data.flgExArmedReg === 'Yes' ? true : false) : null,
                "flgExArmedDivision": true,
                "exServiceManDivision": data.exServiceManDivision ? data.exServiceManDivision : null,
                "dateApptArmedRegular": data.dateApptArmedRegular ? data.dateApptArmedRegular : null,
                "dateRetireArmedRegular": data.dateRetireArmedRegular ? data.dateRetireArmedRegular : null,
                "dischargeCertificateNo": data.dischargeCertificateNo ? data.dischargeCertificateNo.trim() : null,
                "dateDischargeArmedRegular": data.dateDischargeArmedRegular ? data.dateDischargeArmedRegular : null,
                "ageRelaxCertiNo": data.ageRelaxCertiNo ? data.ageRelaxCertiNo.trim() : null,
                "ageRelaxCertiNo1": data.ageRelaxCertiNo1 ? data.ageRelaxCertiNo1 : null,
                "exServiceYears": data.exServiceYears,
                "exServiceMonths": data.exServiceMonths,
                "exServiceDays": data.exServiceDays,
                "flgGomEmployee": data.flgGomEmployee === 'Yes' ? true : false,
                "flgBmcEmployee": data.flgBmcEmployee === 'Yes' ? true : false,
                "flgBestEmployee": data.flgBestEmployee === 'Yes' ? true : false,
                "flgStateEmployee": data.flgStateEmployee === 'Yes' ? true : false,
                "otherLanguages": data.otherLanguages ? data.otherLanguages.trim() : null,
                "flgUnreservedCastValidity": data.flgUnreservedCastValidity ? data.flgUnreservedCastValidity : null,
                "flgNameChanged": data.flgNameChanged === 'Yes' ? true : false,
                "changedEnglishName": data.changedEnglishName ? data.changedEnglishName.trim() : null,
                "typeDisabilityCertificate": data.typeDisabilityCertificate ? data.typeDisabilityCertificate : null,
                "sevaarthId": data.sevaarthId ? data.sevaarthId.trim() : null,
                "flgDisabilityPensionHolder": data.flgDisabilityPensionHolder === 'Yes' ? true : false,
                "flgJoinedStateGovt": data.flgJoinedStateGovt === 'Yes' ? true : false,
                "flgMaharashtraKarnatakBorderArea": data.flgMaharashtraKarnatakBorderArea === 'Yes' ? true : false,
                "borderDistrictLookupId": data.borderDistrictLookupId,
                "borderTalukaLookupId": data.borderTalukaLookupId,
                "borderVillageLookupId": data.borderVillageLookupId,
                "isAdditionalReservation": data.isAdditionalReservation,
                "isProjectAffected":data.isProjectAffected,
                "certificateNoPa":data.certificateNoPa,
                "issuingDatePa":data.issuingDatePa,
                "isEarthquakeAffected":data.isEarthquakeAffected,
                "certificateNoEqa":data.certificateNoEqa,
                "issuingDateEqa":data.issuingDateEqa,
                "isGraduateEmployee":data.isGraduateEmployee,
                "employmentNoGpte":data.employmentNoGpte,
                "issuingDateGpte":data.issuingDateGpte
            }
            let getResponse;
            let response;
            if (profileId) {
                getResponse = await API.get(PROFILE_CREATION + `/${session.profileId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if(getResponse!=null)
                {
                    dispatch(hideLoader());
                }
                if (getResponse.data && getResponse.data.id) {
                    reqBody.id = getResponse.data.id;
                    response = await API.patch(PROFILE_CREATION + `/${profileId}`, reqBody, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if(response!=null)
                {
                    dispatch(hideLoader());
                }
                } else {
                    isFirstPost = true;
                    dispatch(hideLoader());
                }
            } else {
                dispatch(hideLoader());
                isFirstPost = true;
            }
            if (isFirstPost) {
                response = await API.post(PROFILE_CREATION, reqBody, { headers: { 'Authorization': `Bearer ${token}` } });
                if (response && response.data) {
                    const session = JSON.parse(sessionStorage.getItem("session"));
                    session.profileId = response.data.profileId;
                    sessionStorage.setItem("session", JSON.stringify(session));
                }
                if(response!=null)
                {
                    dispatch(hideLoader());
                }
            }
            else {
                dispatch(hideLoader());
            }
            if (response) {
                toastr.success(commonLabels.PERSONAL_INFO_SAVE_NOTE);
                window.scrollTo(0,0);
                dispatch({
                    payload: response.data,
                    type: SET_PERSONAL_INFO
                });
                dispatch({
                    payload: { "personalInfo": response.data.flgSubmittable },
                    type: SET_A_SUBMITTABLE_FLG
                });
            }
        }
        catch (error) {
                    dispatch(hideLoader());
            if (error.response && error.response.data) {
                toastr.error(error.response.data.message);
            } else {
                toastr.error(error.message);
            }
        }
    }
};

export const setSportsDetails = (meritoriousSportsmanDtoList) => {
    return async (dispatch) => {
        dispatch({
            payload: meritoriousSportsmanDtoList,
            type: SET_SPORT_DETAILS_LIST
        });
    }
};

export const clearSportForm = (flag) => {
    return async (dispatch) => {
        dispatch({
            payload: flag,
            type: CLEAR_SPORT_DTO
        });
    }
};

export const editSportsDtails = (data, index) => {
    return async (dispatch) => {
        dispatch({
            payload: { "sportsDetails": data, index },
            type: EDIT_SPORT_DETAILS_LIST
        });
    }
};

export const otherInfoAction = (newaotherinfosDetails) => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let userId = session ? session.userId : null;
        let isFirstPost = false;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token
            let profileId = session.profileId;
            let request = {
                "userId": userId,
                "profileId": profileId,
                "flgSubmittable": 1,
                "flgProsecutionCourtLaw": newaotherinfosDetails.isProsecutionPending,
                "flgBarcouncilInstitution": newaotherinfosDetails.isBarDisciplinaryActionInitiated,
                "flgDispActionGovSemiGov": newaotherinfosDetails.isGovDisciplinaryActionInitiated,
                "flgCourtCaseFilled": newaotherinfosDetails.isCourtCasePending,
                "flgDebaredBlockListExam": newaotherinfosDetails.isDebarredForExamination,
                "natureLookupId": newaotherinfosDetails.nature,
                "debarredStartDate": newaotherinfosDetails.debarredStartDate,
                "debarredEndDate": newaotherinfosDetails.debarredEndDate,
                "activities": newaotherinfosDetails.activities,
                "distinctionLeadershipPosition": newaotherinfosDetails.distinctionLeadershipPosition,
                "extraCurricular": newaotherinfosDetails.extraCurricular,            
            }
            let difference = dateDifferece(request.debarredStartDate, request.debarredEndDate);
            if (difference.firstDateWasLater) {
                toastr.error(commonLabels.FROM_DATE_CANT_GREATER_THAN_TO_DATE);
            }
            else {
                let response;
                let getResponse;
                if (profileId) {
                    getResponse = await API.get(OTHER_INFORMATION + `/${userId}/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
                    if (getResponse.data && getResponse.data.id) {
                        request.id = getResponse.data.id;
                        response = await API.patch(OTHER_INFORMATION + `/${userId}/${profileId}`, request, { headers: { 'Authorization': `Bearer ${token}` } });
                    }
                    else {
                        isFirstPost = true;
                    }
                    if(getResponse!=null)
                        {
                            dispatch(hideLoader());
                        }
                } else {
                    dispatch(hideLoader());
                    isFirstPost = true;
                }
                if (isFirstPost) {
                    response = await API.post(OTHER_INFORMATION, request, { headers: { 'Authorization': `Bearer ${token}` } });
                    if (response && response.data) {
                        const session = JSON.parse(sessionStorage.getItem("session"));
                        session.profileId = response.data.profileId;
                        sessionStorage.setItem("session", JSON.stringify(session));
                    }
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                }else {
                    dispatch(hideLoader());
                }
                if (response) {
                    let otherInformationResponse = {
                        isProsecutionPending: response.data.flgProsecutionCourtLaw,
                        isBarDisciplinaryActionInitiated: response.data.flgBarcouncilInstitution,
                        isGovDisciplinaryActionInitiated: response.data.flgDispActionGovSemiGov,
                        isCourtCasePending: response.data.flgCourtCaseFilled,
                        isDebarredForExamination: response.data.flgDebaredBlockListExam,
                        nature: response.data.natureLookupId,
                        debarredStartDate: response.data.debarredStartDate,
                        debarredEndDate: response.data.debarredEndDate,
                        otherInfoRowId: response.data.id,
                        activities: response.data.activities,
                        distinctionLeadershipPosition: response.data.distinctionLeadershipPosition,
                        extraCurricular: response.data.extraCurricular,                   
                    }
                    toastr.success(commonLabels.OTHER_INFO_SAVE_NOTE);
                    window.scrollTo(0,0);
                    dispatch({
                        payload: otherInformationResponse,
                        type: SET_OTHER_INFORMATION
                    });
                    dispatch({
                        payload: { "otherInfo": response.data.flgSubmittable },
                        type: SET_A_SUBMITTABLE_FLG
                    });
                }
            }
        }
        catch (error) {
                    dispatch(hideLoader());
            if (error.response && error.response.data) {
                toastr.error(error.response.data.message);
            } else {
                toastr.error(error.message);
            }
        }
    }
};

export const getOtherInformation = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let profileId = session ? session.profileId : "";
        if (!profileId) {
            dispatch(hideLoader());
            return
        }
        let token = session.jwtResponse && session.jwtResponse.token
        let userId = session.userId;
        let response = await API.get(OTHER_INFORMATION + `/${userId}/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        if (response && response.data) {
            let debarredStartDate = response.data.debarredStartDate ? dayjs(response.data.debarredStartDate).toDate() : new Date();
            let debarredEndDate = response.data.debarredEndDate ? dayjs(response.data.debarredEndDate).toDate() : new Date();
            let otherInformationResponse = {
                isProsecutionPending: response.data.flgProsecutionCourtLaw,
                isBarDisciplinaryActionInitiated: response.data.flgBarcouncilInstitution,
                isGovDisciplinaryActionInitiated: response.data.flgDispActionGovSemiGov,
                isCourtCasePending: response.data.flgCourtCaseFilled,
                isDebarredForExamination: response.data.flgDebaredBlockListExam,
                nature: response.data.natureLookupId,
                debarredStartDate: debarredStartDate,
                debarredEndDate: debarredEndDate,
                otherInfoRowId: response.data.id,
                activities: response.data.activities,
                distinctionLeadershipPosition: response.data.distinctionLeadershipPosition,
                extraCurricular: response.data.extraCurricular,                   
            }
            dispatch({
                payload: otherInformationResponse,
                type: SET_OTHER_INFORMATION
            });
            dispatch({
                payload: { "otherInfo": response.data.flgSubmittable },
                type: SET_A_SUBMITTABLE_FLG
            });
        }
    }
};

export const getAddressInformation = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let profileId = session ? session.profileId : "";
        if (!profileId) {
            dispatch(hideLoader());
            return
        }
        let token = session.jwtResponse && session.jwtResponse.token
        let userId = session.userId;
        let response;
        try {
            response = await API.get(ADDRESS_INFORMATION + `/${userId}/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
            processAddressInfoResponse(response, dispatch);
            if(response!=null)
                {
                    dispatch(hideLoader());
                }
        } catch (error) {
                    dispatch(hideLoader());
            if (error.response && error.response.data) {
                toastr.error(error.response.data.message);
            } else {
                toastr.error(error.message);
            }
        }

    }
};

export const getUploadedImageAndSignature = () => {
    const session = JSON.parse(sessionStorage.getItem("session"));
    let token = session.jwtResponse && session.jwtResponse.token
    let profileId = session.profileId;
    let response;
    return async (dispatch) => {
        dispatch(showLoader());
        if (session.profileId) {
            try {
                response = await API.get(PHOTO_URL + `/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
                if(response!=null)
                {
                    dispatch(hideLoader());
                }
                if (response && response.data) {
                    dispatch({
                        payload: (response && response.data) ? response.data : [],
                        type: SET_UPLOADED_PHOTO
                    });
                    dispatch({
                        payload: { "uploadPhotoSign": response.data.flgSubmittable },
                        type: SET_A_SUBMITTABLE_FLG
                    });
                }
            } catch (error) {
                    dispatch(hideLoader());
                toastr.error(commonLabels.UPLOADED_PHOTO_SIGNATURE_ERR_NOTE, (error.response && error.response.data) ?
                    error.response.data.message : error.message);
            }
        }
    }
};

export const PostUploadPhotoAndSignature = (data, flag) => {
    const session = JSON.parse(sessionStorage.getItem("session"));
    let token = session.jwtResponse && session.jwtResponse.token
    let profileId = session.profileId;
    let userId = session ? session.userId : null;
    let formData = new FormData();
    let isImageUpload = false
    if (data.photo) {
        formData.append('photo', data.photo);
        isImageUpload = true
    }
    if (data.signature) {
        formData.append('signature', data.signature);
        isImageUpload = true
    }
    if (!data.photo && !data.signature) {
        isImageUpload = false
    }
    if (profileId) {
        formData.append('profileId', profileId);
    }
    formData.append('userId', userId);
    formData.append('flgSubmittable', 1);
    let response;
    let header = {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        "type": "formData",
    }
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            if (flag === 'post' && isImageUpload === true) {
                response = await API.post(PHOTO_URL, formData, {
                    headers: header
                });
            } else if (flag === 'patch' && isImageUpload === true) {
                response = await API.patch(PHOTO_URL, formData, {
                    headers: header
                });
            } else {
                toastr.error(commonLabels.ADD_PHOTO_OR_SIGNATURE);
            }
            if(response!=null)
                {
                    dispatch(hideLoader());
                }
            if (response) {
                if (flag === 'post') {
                    toastr.success(commonLabels.PHOTO_OR_SIGNATURE_UPLOADED_SUCCESSFULLY);
                    let sessionObj = JSON.parse(sessionStorage.getItem("session"));
                    sessionObj.profileId = response.data.profileId;
                    sessionStorage.setItem("session", JSON.stringify(sessionObj));
                } else {
                    toastr.success(commonLabels.PHOTO_OR_SIGNATURE_SAVED_SUCCESSFULLY);
                }
                dispatch(getUploadedImageAndSignature())

            }
        } catch (error) {
                    dispatch(hideLoader());
            toastr.error(commonLabels.ERROR_WHILE_SAVE_PHOTO_OR_SIGNATURE, error.message);
        }
    }
};

export const getAllQualificationTypes = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            let response = await API.get(QUALIFICATION_TYPES);
            if (response && response.data) {
                dispatch({
                    payload: response.data,
                    type: SET_QUALIFICATION_TYPES
                });
            }
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
                    dispatch(hideLoader());
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_FETCHING_QUA_TYPES, errorMessage);
        }
    }
};

export const getQualificationNamesOfAType = (qualificationtypecode) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token
            let response = await API.get(QUALIFICATION_NAME + `?qualificationtypecode=${qualificationtypecode}`, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if (response && response.data) {
                dispatch({
                    payload: response.data,
                    type: SET_QUALIFICATION_NAMES
                });
            }
        } catch (error) {
                dispatch(hideLoader());
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_FETCHING_QUA_NAMES, errorMessage);
        }
    }
};

export const getSubjectsForAQualification = (qualificationNameCode) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            if (qualificationNameCode === "") {
                dispatch(hideLoader());
                return
            }
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token
            let response = await API.get(SUBJECTS + `?qualificationnamecode=${qualificationNameCode}`, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if (response && response.data) {
                dispatch({
                    payload: response.data,
                    type: SET_SUBJECTS
                });
            }
        } catch (error) {
                    dispatch(hideLoader());
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_FETCHING_SUBJECTS, errorMessage);
        }
    }
};

export const getUniversities = (statecode, qualificationnamecode = "NA") => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token
            let response = await API.get(UNIVERSITIES + `/${statecode}/${qualificationnamecode}`, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if (response && response.data) {
                dispatch({
                    payload: response.data,
                    type: SET_UNIVERSITIES
                });
            }
        } catch (error) {
                    dispatch(hideLoader());
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_FETCHING_UNIVERSITIES, errorMessage);
        }
    }
};

const validateQualificationHierarchy = (toBeUpdatedQualificationList) => {
    if (toBeUpdatedQualificationList.length === 1 && toBeUpdatedQualificationList[0].qualificationtypecode === "BSSC") {
        return true;
    } else {
        let qualificationMatch = toBeUpdatedQualificationList
            .filter((qualificaition) => {
                return (qualificaition.flgDeleted === false ||
                    qualificaition.flgDeleted === undefined)
            })
            .findIndex((qualification) => {
                return mandatoryEducation.includes(qualification.qualificationtypecode);
            })
        return (qualificationMatch === -1) ? false : true;
    }
};

export const addQualification = (qualificationToBeAdded, existingQualificationList) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ADD_QUALIFICATION,
                payload: [...existingQualificationList, qualificationToBeAdded]
            })
        } catch (error) {
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_ADDING_QUA_DETAILS, errorMessage);
        }
    }
};

const cascadeDeleteIfConfirmed = (qualificationList,dispatch) => {
    try {
        let message =
            <div className="confirmMessage">
                <p className='word-break'>
                    {qualificationinformation.QUALIFICATION_CASCADE_DELETE_MODAL_TITLE}
                </p>
                <p>
                    {qualificationinformation.QUALIFICATION_CASCADE_DELETE_MODAL_BODY}
                </p>
            </div>
        toastr.confirm(message, {
            onOk: async () => {
                let qualificaitionListForCascadeDelete = [];
                for (let i = 0; i < qualificationList.length; i++) {
                    if (qualificationList[i].qualificationtypecode === "BSSC") {
                        qualificaitionListForCascadeDelete.push(qualificationList[i]);
                        continue;
                    } else {
                        let qualificaitionToBeDelted = qualificationList[i];
                        qualificaitionToBeDelted.flgDeleted = true;
                        qualificaitionListForCascadeDelete.push(qualificaitionToBeDelted);
                    }
                }
                toastr.info(commonLabels.QUA_DELETED_NOTE);
                dispatch({
                    type: ADD_QUALIFICATION,
                    payload: qualificaitionListForCascadeDelete
                })
                dispatch({
                    type: SET_CURRENT_EDIT_QUALIFICATION_INDEX,
                    payload: -1
                });
                dispatch({
                    type: SET_SELECTED_QUALIFICATION_TYPE,
                    payload: "Select"
                })
            }
        });
    } catch (error) {
        toastr.error(commonLabels.ERR_WHILE_DELETING_QUA, error);
    }
};

export const deleteQualification = (qualificationRowIndex, existingQualificationList) => {
    return async (dispatch) => {
        try {
            let toBeUpdatedQualificationList = existingQualificationList;
            toBeUpdatedQualificationList[qualificationRowIndex].flgDeleted = true;
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            let profileId = session.profileId;
            if (!validateQualificationHierarchy(toBeUpdatedQualificationList)) {
                dispatch(getQualificationDetails());
                cascadeDeleteIfConfirmed(toBeUpdatedQualificationList, token, profileId, dispatch);
                return
            }
            toastr.info(commonLabels.QUA_DETAILS_DELETED_NOTE);
            dispatch({
                type: ADD_QUALIFICATION,
                payload: toBeUpdatedQualificationList
            })
            dispatch({
                type: SET_CURRENT_EDIT_QUALIFICATION_INDEX,
                payload: -1
            });
            dispatch({
                type: SET_SELECTED_QUALIFICATION_TYPE,
                payload: "Select"
            })
        } catch (error) {
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_DELETING_QUA_DETAILS, errorMessage);
        }
    }
};

export const getDetailsForEditQualification = (qualificationToBeEdited) => {
    return async (dispatch) => {
        dispatch({
            type: EDIT_QUALIFICATION,
            payload: qualificationToBeEdited
        })
        let qualificationtypecodeEdit = qualificationToBeEdited.qualificationtypecode;
        let qualificationnamecodeEdit = qualificationToBeEdited.qualificationnamecode;
        let stateCodeEdit = qualificationToBeEdited.statecode;
        dispatch({
            type: SET_SELECTED_QUALIFICATION_TYPE,
            payload: qualificationtypecodeEdit
        });
        dispatch({
            type: IS_QUALIFICATION_TYPE_CHANGED_BY_DROPDOWN,
            payload: false
        });
        if (qualificationtypecodeEdit !== "Ph.D Guide") {
            dispatch(getQualificationNamesOfAType(qualificationtypecodeEdit));
        }
        if (qualificationnamecodeEdit) {
            dispatch(getSubjectsForAQualification(qualificationnamecodeEdit));
        }
        if (stateCodeEdit) {
            if (["SSC", "HSC", "AMIE"].includes(qualificationnamecodeEdit)) {
                dispatch(getUniversities(stateCodeEdit, qualificationnamecodeEdit));
            } else {
                dispatch(getUniversities(stateCodeEdit));
            }
        }
    }
};

export const editQualification = (qualificationToBeEdited, existingQualificationList, currentEditQualificationIndex) => {
    return async (dispatch) => {
        try {
            if (currentEditQualificationIndex !== -1) {
                existingQualificationList[currentEditQualificationIndex] = qualificationToBeEdited
                toastr.info(commonLabels.REC_UPDATED_NOTE);
                dispatch({
                    type: SET_CURRENT_EDIT_QUALIFICATION_INDEX,
                    payload: -1
                });
                dispatch({
                    type: ADD_QUALIFICATION,
                    payload: existingQualificationList
                })
                dispatch({
                    type: EDIT_QUALIFICATION_DONE_RESET
                })
            }
        } catch (error) {
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_EDITING_QUA_DETAILS, errorMessage);
        }
    }
};

export const saveQualificationsList = (qualificationsList) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            if (qualificationsList.length === 0) {
                dispatch(hideLoader());
                toastr.info(commonLabels.NO_QUA_ADDED_NOTE);
                return
            }
            let toBeUpdatedQualificationList = qualificationsList;
            if (!validateQualificationHierarchy(toBeUpdatedQualificationList)) {
                dispatch(hideLoader());
                toastr.warning(qualificationinformation.QUALIFICATION_CASCADE_DELETE_MODAL_TITLE);
                return
            }
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            let profileId = session.profileId;
            let userId = session.userId;
            let isFirstPost = false;
            let request = []
            for (let i = 0; i < qualificationsList.length; i++) {
                request.push({
                    flgDeleted: 0,
                    profileId: profileId,
                    ...qualificationsList[i],
                    userId: userId,
                    flgSubmittable: 1,
                    flgDelete: 0
                })
            }
            let response;
            let getResponse;
            if (profileId) {
                getResponse = await API.get(QUALIFICATION_INFORMATION + `/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
                if(getResponse!=null)
                {
                    dispatch(hideLoader());
                }
                if (getResponse.data && getResponse.data.length > 0) {
                    response = await API.patch(QUALIFICATION_INFORMATION + `/${profileId}`, request, { headers: { 'Authorization': `Bearer ${token}` } });
                    // window.location.reload();
                }
                else {
                    isFirstPost = true;
                }
            } else {
                dispatch(hideLoader());
                isFirstPost = true;
            }
            if (isFirstPost) {
                response = await API.post(QUALIFICATION_INFORMATION, request, { headers: { 'Authorization': `Bearer ${token}` } });
                if (response && response.data) {
                    const session = JSON.parse(sessionStorage.getItem("session"));
                    session.profileId = response.data[0].profileId;
                    sessionStorage.setItem("session", JSON.stringify(session));
                    // window.location.reload();
                }
                if(response!=null)
                {
                    dispatch(hideLoader());
                }
            }
            else
            {
                dispatch(hideLoader());
            }
            if (response) {
                toastr.success(commonLabels.QUA_INFO_SAVED_NOTE);
                window.scrollTo(0,0);
                dispatch(getQualificationDetails());
                dispatch({
                    type: SET_CURRENT_EDIT_QUALIFICATION_INDEX,
                    payload: -1
                });
                dispatch({
                    type: SET_SELECTED_QUALIFICATION_TYPE,
                    payload: "Select"
                })
            }
        } catch (error) {
                    dispatch(hideLoader());
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(errorMessage);
        }
    }
};

export const getQualificationDetails = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            let profileId = session.profileId;
            if (!profileId) {
                dispatch(hideLoader());
                return
            }
            let response = await API.get(QUALIFICATION_INFORMATION + `/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if (response && response.data) {
                processQualificationInfoResponse(response, dispatch);
            }
        } catch (error) {
                    dispatch(hideLoader());
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_FETCHING_QUA_DETAILS, errorMessage);
        }
    }
};

export const getDisplayableQualificationList = (crudQualificationsList) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            let response = await API.post(GET_DISPLAYABLE_QUALIFICATION_LIST, crudQualificationsList, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if (response && response.data) {
                dispatch({
                    type: SET_DISPLAYABLE_QUALIFICATION_LIST,
                    payload: response.data
                })
            }
        } catch (error) {
                    dispatch(hideLoader());
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(commonLabels.ERROR_WHILE_FETCHING_QUA_TABLE, errorMessage);
        }
    }

};

export const setImagesrc = (imageSrc) => {
    return async (dispatch) => {
        dispatch({
            payload: imageSrc,
            type: SET_IMAGE_SRC
        })
    }
};

export const setSignaturesrc = (signatureSrc) => {
    return async (dispatch) => {
        dispatch({
            payload: signatureSrc,
            type: SET_SIGNATURE_SRC
        })
    }
};

export const cropperImageURL = (copperImage) => {
    return function (dispatch) {
        dispatch({
            payload: copperImage.cropImage,
            type: SET_CROPE_IMAGE_SRC
        })
    }
};

export const setCropperSignaturesrc = (signatureSrc) => {
    return async (dispatch) => {
        dispatch({
            payload: signatureSrc,
            type: SET_CROP_SIGNATURE_SRC
        })
    }
};

export const showImageSignatureFlag = (isImageShow, isSignatureShow) => {
    let obj = {
        isImageShow: isImageShow,
        isSignatureShow: isSignatureShow
    }
    return async (dispatch) => {
        dispatch({
            payload: obj,
            type: SET_IMAGE_SIGNATURE_FLAG
        })
    }
};

export const getProfileStatus = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let profileId = session.profileId;
        let profileStatus = false
        if (!profileId) {
            dispatch(hideLoader());
            return profileStatus;
        }
        let response = await API.get(PROFILE + `/${profileId}`, { headers: { 'Authorization': `Bearer ${token}` } });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        profileStatus = (response && response.data) ? response.data : false;
        dispatch({
            payload: profileStatus,
            type: SET_PROFILE_STATUS
        });
    }
};

export const submitProfile = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            let profileId = session.profileId;
            let userId = session.userId;
            let response = await API.patch(PROFILE + `/${userId}/${profileId}`, {}, { headers: { 'Authorization': `Bearer ${token}` } });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            let profileStatus = false;
            if (response && response.data) {
                profileStatus = response.data.flgLocked;
                toastr.success(commonLabels.CANDIDATE_PROFILE_NOTE);
                window.scrollTo(0,0);
            } else {
                profileStatus = false;
            }
            dispatch({
                payload: false,
                type: SET_UPDATE_PROFILE_DONE,
            });
            dispatch({
                payload: profileStatus,
                type: SET_PROFILE_STATUS
            });
        } catch (error) {
                    dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(commonLabels.ERR_WHILE_SUBMITTING_CANDIDATE_PROFILE, errorMessage);
        }
    }
};

export const disableAllSaveAfterSubmit = (flag) => {
    return function (dispatch) {
        dispatch({
            payload: flag,
            type: SET_DISABLE_ALL_TAB_SAVE
        })
    }
};

export const setExperienceTableList = ( data ) => {
    return function (dispatch) {
        dispatch({
            payload: data,
            type: SET_EXPERIENCE_TABLE_LIST
        })
    }
};

export const getAdditionResEnvData=()=>{
    return async (dispatch) => {
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            let response = await API.get(GET_ADDITIONAL_RES_ENV, { headers: { 'Authorization': `Bearer ${token}` } });
                if(response!=null)
                {
                    dispatch({
                        payload: response.data,
                        type: SET_ADDITIONAL_RES_ENV_DATA
                    })
                }
        } catch (error) {
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error("Error :", errorMessage);
        }
    }
};

export const getPublicationTypeData = (qualificationtypecode) =>{
    return async (dispatch) => {
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            let token = session.jwtResponse && session.jwtResponse.token;
            let response = await API.get(GET_PUBLICATION_TYPE_DATA + `?qualificationtypecode=${qualificationtypecode}`, { headers: { 'Authorization': `Bearer ${token}` } });
                if(response!=null)
                {
                    dispatch({
                        payload: response.data,
                        type: SET_PUBLICATION_TYPE_DATA
                    })
                }
        } catch (error) {
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error("Error :", errorMessage);
        }
    }
};

export const getAllNameOfIndexingBodies = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response = await API.get(LOOKUP_OPTIONS, {
            params: {
                 module: "profile_creation",
                 type: "publication_indexing_bodies"
                }
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: (response && response.data) ? response.data : [],
            type: GET_NAME_OF_INDEXING_BODIES
        });
    }
};

export const getAllPublicationArticleTypes = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response = await API.get(LOOKUP_OPTIONS, {
            params: {
                 module: "profile_creation",
                 type: "publication_article_type"
                }
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: (response && response.data) ? response.data : [],
            type: GET_TYPE_OF_ARTICLES
        });
    }
};

export const getAllJobDesignationDetails = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response = await API.get(LOOKUP_OPTIONS, {
            params: {
                 module: "profile_creation",
                 type: "designation"
                }
        });
        if(response!=null)
        {
            dispatch(hideLoader());
        }
        dispatch({
            payload: (response && response.data) ? response.data : [],
            type: GET_ALL_JOB_DESIGNATION_LIST
        });
    }
};

export const validateUdidAPI = (requestData) => {
    return async (dispatch) => {
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let profileId = session.profileId;
        let response;
        try {
            response = await API.post(VALIDATE_UDID + `/${profileId}`, requestData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response && response.data){
                // {
                //     dispatch({
                //         payload: response.data.flgUdid,
                //         type: SET_UDID_FLAG
                //     })
                // }
                dispatch({
                    payload: response.data,
                    type: SET_UDID_RESPONSE_MESSAGE
                })
            }
            // let successMessage = response.data.message ? response.data.message : response.message;
            // toastr.success(successMessage);
        } catch (error) {
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    }
};

export const verifyUdidAPI = (requestData) => {
    return async (dispatch) => {
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let profileId = session.profileId;
        let response;
        try {
            response = await API.post(VERIFY_UDID + `/${profileId}`, requestData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response && response.data){
                {
                    dispatch({
                        payload: response.data.flgUdid,
                        type: SET_UDID_FLAG
                    })
                }
            }
            let successMessage = response.data.message ? response.data.message : response.message;
            toastr.success(successMessage);
        } catch (error) {
            let errorMessage = (error.response && error.response.data) ?
                error.response.data.message : error.message
            toastr.error(errorMessage);
            return;
        }
    }
};