/* eslint-disable */
import { SET_ADDRESS_INFO, SET_DISTRICTS,SET_DISTRICTS_CORRESPONDENCE, SET_ISSUING_DISTRICTS, SET_PROFILE_CREATION_LOOKUPS, 
    SET_STATES, SET_TALUKAS,SET_TALUKAS_CORRESPONDENCE, SET_VILLAGES, SET_VILLAGES_CORRESPONDENCE, SET_OTHER_INFORMATION, SET_PERSONAL_INFO, 
    SET_EXPERIENCE_INFO, SET_QUALIFICATION_TYPES,PERSONAL_INFORMATION, SET_QUALIFICATION_NAMES, SET_SUBJECTS, SET_UNIVERSITIES, SET_UPLOADED_PHOTO, SET_QUALIFICATION_INFORMATION, ADD_QUALIFICATION,ADD_EXPERIENCEINFO,
    SET_DISPLAYABLE_QUALIFICATION_LIST, SET_IMAGE_SRC, SET_CROPE_IMAGE_SRC, SET_SIGNATURE_SRC,
    SET_CROP_SIGNATURE_SRC, SET_IMAGE_SIGNATURE_FLAG, SET_SELECTED_QUALIFICATION_TYPE, 
    CLEAR_QUALIFICATION_INFORMATION, 
    EDIT_EXPERIENCEINFO,
    EDIT_QUALIFICATION,
    EDIT_QUALIFICATION_DONE_RESET,
    SET_PROFILE_STATUS,
    SET_A_SUBMITTABLE_FLG,
    SET_EXPERIENCEINFO_EDIT_FLAG, SET_DISABLE_ALL_TAB_SAVE, SET_CURRENT_EDIT_QUALIFICATION_INDEX, IS_QUALIFICATION_TYPE_CHANGED_BY_DROPDOWN, SET_SPORT_DETAILS_LIST, EDIT_SPORT_DETAILS_LIST, CLEAR_SPORT_DTO, SET_EXPERIENCE_TABLE_LIST, SET_ADDITIONAL_RES_ENV_DATA,
    SET_ALL_DISABILITIES_DATA, SET_PUBLICATION_TYPE_DATA,GET_NAME_OF_INDEXING_BODIES, GET_TYPE_OF_ARTICLES, GET_ALL_JOB_DESIGNATION_LIST,SET_UDID_FLAG, SET_UDID_RESPONSE_MESSAGE
} from '../../common/constants/ActionTypes';

const initialState = {
    experienceTableList : [],
    flgAddressSuccess: false,
    personalInformationResponce: "data",
    otherInformationResponse: [],
    addressInformationResponse: {
        permenantAddressState: {
            permenantHouseNo: "",
            permenantBuildingName: "",
            permenantRoadOrPo: "",
            permenantArea: "",
            permenantLandmark: "",
            permenantState: "",
            permenantDistrict: "",
            permenantTaluka: "",
            permenantVillageCity: "",
            permenantPin: ""
        },
        correspondenceAddressState: {
            correspondenceHouseNo: "",
            correspondenceBuildingName: "",
            correspondenceRoadOrPo: "",
            correspondenceArea: "",
            correspondenceLandmark: "",
            correspondenceState: "",
            correspondenceDistrict: "",
            correspondenceTaluka: "",
            correspondenceVillageCity: "",
            correspondencePin: ""
        }
    },
    profileCreationAllLookupData: [],
    allDisabilitiesData:[],
    statesList: [],
    districtList: [],
    districtCorrespondenceList:[],
    talukaList: [],
    talukaCorrespondenceList: [],
    villageList: [],
    villageCorrospondenceList : [],
    issuingDistrictList: [],
    personalInfo: {},
    qualificationTypes: [],
    qualificationtypecode: "Select",
    qualificationNames: [],
    subjects: [],
    universities: [],
    uploadedPhotoAndSignature:{},
    qualificationInformationResponse: {},
    qualificationsList: [],
    displayableQualificationList: [],
    experiencesList: [],
    imageSRC : '',
    cropImageSRC:{},
    signatureSRC: '',
    cropSignatureSRC:{},
    isShowImageCropper:false,
    isShowSignatureCropper:false,
    qualificationToBeEdited: { qualificationtypecode: "Select" },
    profileStatus: false,
    flgSubmittableList: {
        personalInfo: false,
        addressInfo: false,
        otherInfo: false,
        qualificationInfo: false,
        experienceInfo: false,
        uploadPhotoSign: false
    },
    flagQualificationEdit: false,
    isDisableAllTabSave: false,
    flagExperienceEdit: false,
    currentEditQualificationIndex: -1,
    isQualificationTypeChangedByDropdown: false,
    meritoriousSportsmanDtoList: [],
    editSportsData:{},
    sportsTableIndex: -1,
    clearSportDTO: false,
    additionResEnvData:[],
    publicationTypeList: [],
    nameOfIndexingBodiesList: [],
    getPublicationArticleTypeList: [],
    getJobDesignationList: [],
    udidFlg: false,
    udidResponseMessage:''
}

export default function profileReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ADDRESS_INFO:
            return {
                ...state,
                addressInformationResponse: action.payload
            }
        case PERSONAL_INFORMATION:
            return {
                ...state,
                personalInformationResponce: action.payload
            }
        case SET_PROFILE_CREATION_LOOKUPS:
            return {
                ...state,
                profileCreationAllLookupData: action.payload
            }
        case SET_ALL_DISABILITIES_DATA:
            return {
                ...state,
                allDisabilitiesData: action.payload
            }
        case SET_STATES:
            return {
                ...state,
                statesList: action.payload
            }
        case SET_DISTRICTS:
            return {
                ...state,
                districtList: action.payload
            }
        case SET_DISTRICTS_CORRESPONDENCE:
            return {
                ...state,
                districtCorrespondenceList: action.payload
            }
        case SET_TALUKAS:
            return {
                ...state,
                talukaList: action.payload
            }
        case SET_TALUKAS_CORRESPONDENCE: 
        return {
            ...state,
            talukaCorrespondenceList: action.payload
        }
        case SET_VILLAGES:
            return {
                ...state,
                villageList: action.payload
            }
        case SET_VILLAGES_CORRESPONDENCE: 
            return {
                ...state,
                villageCorrospondenceList: action.payload
            }
        case SET_ISSUING_DISTRICTS:
            return {
                ...state,
                issuingDistrictList: action.payload
            }
        case SET_PERSONAL_INFO:
            return {
                ...state,
                personalInfo: action.payload
            }
        case SET_OTHER_INFORMATION:
            return {
                ...state,
                otherInformationResponse: action.payload
            }
        case SET_EXPERIENCE_INFO:
            return {
                ...state,
                experiencesList: action.payload
            }
        case ADD_EXPERIENCEINFO:
            return{
                ...state,
                experiencesList:action.payload
            }
        case SET_UPLOADED_PHOTO: 
            return {
                ...state,
                uploadedPhotoAndSignature: action.payload
            }
        case SET_QUALIFICATION_TYPES:
            return {
                ...state,
                qualificationTypes: action.payload
            }
        case SET_SELECTED_QUALIFICATION_TYPE:
            return {
                ...state,
                qualificationtypecode: action.payload
            }
        case IS_QUALIFICATION_TYPE_CHANGED_BY_DROPDOWN:
            return {
                ...state,
                isQualificationTypeChangedByDropdown: action.payload
            }
        case SET_QUALIFICATION_NAMES:
            return {
                ...state,
                qualificationNames: action.payload
            }
        case SET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload
            }
        case SET_UNIVERSITIES:
            return {
                ...state,
                universities: action.payload
            }
        case SET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload
            }
        case SET_UNIVERSITIES:
            return {
                ...state,
                universities: action.payload
            }
        case SET_CURRENT_EDIT_QUALIFICATION_INDEX:
            return {
                ...state,
                currentEditQualificationIndex: action.payload
            }
        case SET_QUALIFICATION_INFORMATION:
            return {
                ...state,
                qualificationsList: action.payload
            }
        case ADD_QUALIFICATION:
            return {
                ...state,
                qualificationsList: [...action.payload]
            }
        case EDIT_QUALIFICATION:
            return {
                ...state,
                qualificationToBeEdited: action.payload
            }
        case CLEAR_QUALIFICATION_INFORMATION:
            return {
                ...state,
                qualificationsList: []
            }
        case EDIT_EXPERIENCEINFO:
            return{
                ...state,
                experienceToBeEdited:action.payload
            }
        case SET_DISPLAYABLE_QUALIFICATION_LIST:
            return {
                ...state,
                displayableQualificationList: action.payload
            }
        case EDIT_QUALIFICATION_DONE_RESET:
            return {
                ...state,
                qualificationToBeEdited: { qualificationtypecode: "Select" }
            }
        case SET_IMAGE_SRC: 
            return{
                ...state,
                imageSRC : action.payload
            }
        
        case SET_CROPE_IMAGE_SRC:
            return{
                ...state,
                cropImageSRC : action.payload
            }
        case SET_SIGNATURE_SRC: 
            return {
                ...state,
                signatureSRC: action.payload
            }
        case SET_CROP_SIGNATURE_SRC:
            return{
                ...state,
                cropSignatureSRC : action.payload
            }
        case SET_IMAGE_SIGNATURE_FLAG: 
            return{
                ...state,
                isShowImageCropper : action.payload.isImageShow,
                isShowSignatureCropper: action.payload.isSignatureShow
            }
        case SET_DISABLE_ALL_TAB_SAVE: 
            return{
                ...state,
                isDisableAllTabSave : action.payload,
            }
        case SET_PROFILE_STATUS: 
            return{
                ...state,
                profileStatus: action.payload
            }
        case SET_A_SUBMITTABLE_FLG:
            let updatedFlgSubmittableList = Object.assign({}, 
                                                state.flgSubmittableList, 
                                            action.payload);

            return {
                ...state,
                flgSubmittableList: updatedFlgSubmittableList
            }
        case SET_EXPERIENCEINFO_EDIT_FLAG:
            return{
                ...state,
                flagExperienceEdit:action.payload
            }
        case SET_SPORT_DETAILS_LIST:
            return {
                ...state,
                meritoriousSportsmanDtoList: action.payload
            }

        case EDIT_SPORT_DETAILS_LIST:
            return {
                ...state,
                editSportsData: action.payload.sportsDetails,
                sportsTableIndex: action.payload.index
            }
        case CLEAR_SPORT_DTO:
            return {
                ...state,
                clearSportDTO: action.payload,
            }
        case SET_EXPERIENCE_TABLE_LIST:
            return {
                ...state,
                experienceTableList: action.payload,
            }
        case SET_ADDITIONAL_RES_ENV_DATA:
            return {
                ...state,
                additionResEnvData: action.payload,
            } 
        case SET_PUBLICATION_TYPE_DATA:
            return {
                ...state,
                publicationTypeList: action.payload,
            }           
        case GET_NAME_OF_INDEXING_BODIES:
            return {
                ...state,
                nameOfIndexingBodiesList: action.payload,
            } 
        case GET_TYPE_OF_ARTICLES:
            return {
                ...state,
                getPublicationArticleTypeList: action.payload,
            }
        case GET_ALL_JOB_DESIGNATION_LIST:
            return {
                ...state,
                getJobDesignationList: action.payload,
            }
        case SET_UDID_FLAG:
            return {
                ...state,
                udidFlg: action.payload,
            }
        case SET_UDID_RESPONSE_MESSAGE:
            return {
                ...state,
                udidResponseMessage: action.payload,
            }
        default:
            return state;
    }
}